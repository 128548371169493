/* eslint-disable camelcase */
import { OriginalResponse } from 'lib/handlers/requestTypes';
import { IChoosableBaseInfo } from '../generalTypes';
import { ICompanyInfo } from './proposals';

export enum AdministrationActions {
	SET_LOADING = '@@administration/administration/SET_LOADING',
	SET_LOADING_FALSE = '@@administration/administration/SET_LOADING_FALSE',
	SET_ERROR = '@@personalInfo/SET_ERROR',
	GET_EMAIL_TEMPLATES = '@@administration/administration/GET_EMAIL_TEMPLATES',
	POST_CHANGE_EMAIL_TEMPLATE_STATUS = '@@administration/administration/POST_CHANGE_EMAIL_TEMPLATE_STATUS',
	POST_TEST_EMAIL_TEMPLATE = '@@administration/administration/POST_TEST_EMAIL_TEMPLATE',
	POST_NEW_EMAIL_TEMPLATE = '@@administration/administration/POST_NEW_EMAIL_TEMPLATE',
	GET_FILE_TEMPLATES = '@@administration/administration/GET_FILE_TEMPLATES',
	POST_CHANGE_FILE_TEMPLATE_STATUS = '@@administration/administration/POST_CHANGE_FILE_TEMPLATE_STATUS',
	GET_DOWNLOAD_FILE_TEMPLATE = '@@administration/administration/GET_DOWNLOAD_FILE_TEMPLATE',
	POST_UPLOAD_FILE_TEMPLATE = '@@administration/administration/POST_UPLOAD_FILE_TEMPLATE',
	GET_CACHES_LIST = '@@administration/administration/GET_CACHES_LIST',
	DELETE_SINGLE_CACHE = '@@administration/administration/DELETE_SINGLE_CACHE',
	DELETE_ALL_CACHES = '@@administration/administration/DELETE_ALL_CACHES',
	DELETE_ALL_USER_CACHES = '@@administration/administration/DELETE_ALL_USER_CACHES',
	DELETE_ALL_COMPANY_CACHES = '@@administration/administration/DELETE_ALL_COMPANY_CACHES',
	DELETE_ALL_SINGLE_USER_CACHES = '@@administration/administration/DELETE_ALL_SINGLE_USER_CACHES',
	GET_ACCESS_CONTROL_LIST = '@@administration/administration/GET_ACCESS_CONTROL_LIST',
	REMOVE_ALL_ACCESSES = '@@administration/administration/REMOVE_ALL_ACCESSES',
	GET_ACCESS_CONTROL_LIST_ADD_USER = '@@administration/administration/GET_ACCESS_CONTROL_LIST_ADD_USER',
	GET_ACCESS_CONTROL_EDIT_USER = '@@administration/administration/GET_ACCESS_CONTROL_EDIT_USER',
	PUT_ACCESS_CONTROL = '@@administration/administration/PUT_ACCESS_CONTROL',
	CLEAR_EDIT_USER = '@@administration/administration/CLEAR_EDIT_USER',
	SET_AUTHORIZATION_CODE_RENEWAL = '@@administration/administration/SET_AUTHORIZATION_CODE_RENEWAL',
	GET_ALL_RELEASES_LIST = '@@administration/administration/GET_ALL_RELEASES_LIST',
	GET_RELEASE_NOTE_LIST = '@@administration/administration/GET_RELEASE_NOTE_LIST',
	GET_RELEASENOTES_EMAIL = '@@administration/administration/GET_RELEASENOTES_EMAIL',
	CLEAR_RELEASENOTES_EMAIL = '@@administration/administration/CLEAR_RELEASENOTES_EMAIL',
	SEND_RELEASENOTES_EMAIL = '@@administration/administration/SEND_RELEASENOTES_EMAIL',
	SEND_RESET_ALL_NEW_RELEASE_POPUPS = '@@administration/administration/SEND_RESET_ALL_NEW_RELEASE_POPUPS',
	GET_JOB_EXECUTIONS_LIST = '@@administration/administration/GET_JOB_EXECUTIONS_LIST',
	GET_JOBS_LIST = '@@administration/administration/GET_JOBS_LIST',
	DELETE_JOB_EXECUTION = '@@administration/administration/DELETE_JOB_EXECUTION',
	DELETE_ALL_JOBS_EXECUTIONS = '@@administration/administration/DELETE_ALL_JOBS_EXECUTIONS',
	GET_APP_ROLES_LIST = '@@administration/administration/GET_APP_ROLES_LIST',
	DELETE_APP_ROLE = '@@administration/administration/DELETE_APP_ROLE',
	GET_APP_LINKS_LIST = '@@administration/configurations/GET_APP_LINKS_LIST',
	GET_APP_GUIDES_LIST = '@@administration/administration/GET_APP_GUIDES_LIST',
	GET_ROLES_LIST = '@@administration/administration/GET_ROLES_LIST',
	POST_UPLOAD_GUIDES = '@@administration/administration/POST_UPLOAD_GUIDES',
	DEACTIVATED_GUIDES = '@@administration/administration/DEACTIVATED_GUIDES'
}

export type AdministrationState = {
	readonly loading: boolean;
	readonly error: OriginalResponse | null;
	readonly listEmailTemplates: IEmailTemplates | null;
	readonly changeEmailTemplateStatus: OriginalResponse | null;
	readonly newEmailTemplate: INewEmailTemplate | null;
	readonly listFileTemplates: IFileTemplates | null;
	readonly changeFileTemplateStatus: OriginalResponse | null;
	readonly downloadFileTemplate: null;
	readonly uploadFileTemplate: null;
	readonly listCaches: ICachesListInfo | null;
	readonly deleteSingleCache: null;
	readonly deleteAllCaches: null;
	readonly accessControlList: IAccessControl | null;
	readonly removeAllAccesses: null;
	readonly accessControlListAddUser: IAccessControl | null;
	readonly accessControlEditUser: IGrantAccessControlInfo | null;
	readonly accessControlPut: null;
	readonly codeRenewalActions: null;
	readonly resetAllNewReleasePopups: null;
	readonly listReleases: any | null;
	readonly listReleaseNotes: any | null;
	readonly releaseNotesEmail: any | null;
	readonly releaseNotesEmailAction: any | null;
	readonly jobExecutionsData: any | null;
	readonly appLinksData: any | null;
	readonly rolesListData: any | null;
	readonly guidesData: IGuide[] | null;
};

export interface IReleaseNotesEmail {
	subject: string;
	to: string;
	cc: string;
	mailBody: string;
}

export interface IJobExecution {
	endDate: string;
	id: string;
	message: string;
	name: string;
	result: string;
	startDate: string;
	throwable: string;
}

export interface IEmailTemplates {
	emailTemplates?: IEmailTemplate[];
}
export interface IEmailTemplate {
	id: number;
	name: string;
	content: string;
	active: boolean;
}

export interface INewEmailTemplate {
	name: string;
	content: string;
}

export interface IFileTemplates {
	fileTemplates?: IFileTemplate[];
}
export interface IFileTemplate {
	id: number;
	fileName: string;
	created: string;
	createdBy: string;
	active: boolean;
	activeParsed?: string;
}

// Duvida
export interface ICachesListInfo {
	caches: ICache[];
}

export interface ICache {
	id: number;
	source: string;
	name: string;
	lastUpdateDate: string;
}

export interface IAccessControl {
	users: IAccessControlEntry[];
}

export interface IAccessControlEntry {
	id: number;
	username: string;
	name: string;
	acknowlegedPrivacyStatement: boolean;
	acknowlegedNewRelease: boolean;
	hasUpdatedConsents: boolean;
	lastUpdateDate: string;
	summaryData: string;
	status: number;
	statusAsString: string;
	roles?: IChoosableBaseInfo[];
}

export interface IRoles {
	businessUnit: string;
	company: string;
	department: string;
	division: string;
	uniqueId?: string;
}

export interface IOrgAccessesCompare {
	businessUnit?: string | null;
	company?: string | null;
	department?: string | null;
	division?: string | null;
	uniqueId: string | number;
}

export interface IOrgAccesses {
	id: number;
	company?: IChoosableBaseInfo;
	name: string;
}
export interface IAccessControlUsersInfo {
	id: number;
	name: string;
}

export interface ICompanyTree {
	childNodes: ICompanyTree[];
	node: string;
	organization: string;
	organization$_identifier: string;
	reportSet: string;
}

export interface IGrantAccessControlInfo {
	companies: ICompanyInfo[];
	roles: IChoosableBaseInfo[];
	user: IUserData;
}

export interface IGrantAccessControl {
	id?: number;
	orgAccesses?: IOrgAccesses[];
	roles?: IRoles[];
}

export interface IUserData {
	id: number;
	name: string;
	username: string;
	lastUpdateDate: string;
	hasUpdatedConsents: boolean;
	acknowledgedPrivacyStatement: boolean;
	acknowlegedNewRelease: boolean;
	external: boolean;
	organization$_identifier: string;
	orgAccesses?: IOrgAccesses[];
	roles?: IChoosableBaseInfo[];
}

export interface IRoleData {
	roles: IRole[];
	userRoles: any[];
}
export interface IRole {
	id?: number;
	name: string;
	users: IUserData[];
	usersCount: number;
}

export interface IGuideStep {
	id: string;
	guideId: string;
	content: string;
	placement: string;
	position: string;
	title: string;
}

export interface IGuide {
	id: string;
	guideName: string;
	guideSteps: IGuideStep[];
}
