/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, FC, useMemo, ChangeEventHandler } from 'react';
import { useDispatch } from 'react-redux';
import { useStyles } from './styles';
import { Toggler, GoToCard, RightSidebar, Dialog, Table, TabPanel, TabHeader, Tab } from 'xpand-ui/core';
import { InfoField, Input as LibInput } from 'xpand-ui/forms';

import {
	Grid,
	Typography,
	CircularProgress,
	Input,
	MenuItem,
	FormControl,
	Select,
	ListSubheader,
	TextField
} from '@mui/material';
import {
	calculateDiferenceHoursMinutes,
	calculateEndTime,
	decimalToHours,
	formatStringPTToDate,
	XTRACKER_ABSENCE_PROJECTS
} from '../utils';
import { ITableColumn } from 'typings/store/ComponentLib';
import { getRandomInt } from 'pages/PersonalInfo/Bookings/utils';
//* COMPONENT INTERFACES

const ImportModal: FC<any> = ({
	modalImportOpen,
	setModalImportOpen,
	getOutlookEvents,
	outlookEvents,
	setMergedArray,
	weekViewData,
	filterUser,
	selectedFile,
	setSelectedDate,
	setCurrentView,
	loadCurrentPeriodDate,
	setIsImport,
	dailySumsWithRounded30mins,
	setSelectedFile,
	downloadImportTemplate
}) => {
	const classes = useStyles();
	const [tabSelected, setTabSelected] = useState(0);
	const [outlookEventsHelper, setOutlookEventsHelper] = useState(null);
	const [searchProjects, setSearchProjects] = useState<string[]>([]);

	//Gets the necessary data from the file and loads the data from that week
	const onSubmitImportTimesheet = event => {
		event.preventDefault();
		const file = selectedFile;
		const reader = new FileReader();
		reader.onload = async event => {
			const fileContent = event!.target!.result;
			const lines = fileContent!.split('\n');
			const parsedData = lines.map(line => line.split(',')); // Gets the data from the row by ,
			//Filters the necessary info
			parsedData.map(e => {
				e[0] = e[0].replace(/"/g, '');
			});

			const eventsInsideDocument = parsedData
				.sort((a, b) => (formatStringPTToDate(a[0]) > formatStringPTToDate(b[0]) ? 1 : -1))
				.filter(importedEvent => importedEvent[0].includes('/'))
				.filter(importedEvent => !isNaN(Date.parse(formatStringPTToDate(importedEvent[0]).toISOString())));

			//Gets the first day of the list inside of the document
			const [date, time] = eventsInsideDocument[0][0].split(' ');
			const [day, month, year] = date.split('/');
			const firstDayOfWeek = new Date(year, month - 1, day);

			//Goes to the week of the import, sets the view to week (easier to load all data), loads the data from that week
			setSelectedDate(firstDayOfWeek);
			setCurrentView('workWeek');
			loadCurrentPeriodDate(firstDayOfWeek.toISOString());
			setIsImport(true);
		};

		reader.readAsText(file);
		setModalImportOpen(false);
		//setSelectedFile(null);
	};

	const onSubmitImportOutlook = () => {
		if (outlookEventsHelper != null) {
			outlookEventsHelper.forEach(e => {
				if (e.taskValue != null) {
					// Helps to update dailySumsWithRounded30mins value
					const dayOfWeek = new Date(e.start).toLocaleDateString('en-US', { weekday: 'short' });
					const timeDailySum = decimalToHours(dailySumsWithRounded30mins[dayOfWeek]);
					// Get logged hours and minutes from event
					const diffHours = calculateDiferenceHoursMinutes(new Date(e.start), new Date(e.end));

					//	Adjusts event start to the last place of the day (considering entries rounded to 30 min)
					e.start = calculateEndTime(
						new Date(e.start).setHours(0, 0, 0, 0),
						timeDailySum.hours,
						timeDailySum.minutes
					).toISOString();

					// End date = Adds old logged hours and minutes to the new start date
					e.end = calculateEndTime(new Date(e.start), diffHours.hours, diffHours.minutes).toISOString();

					setMergedArray(prevMergedArray => [
						...prevMergedArray,
						{
							id: 'mbsc_' + `${getRandomInt()}`,
							title: e.projectName,
							timeEntryDate: e.date,
							projectName: e.projectName,
							projectTaskName: e.projectTaskName,
							description: e.subject,
							start: e.start,
							end: e.end,
							accountProjectId: e.projectValue,
							projectCode: e.projectCode,
							accountProjectTaskId: e.taskValue,
							projectTaskCode: e.projectTaskCode,
							accountCostCenterId: e.costCenterValue.toString(),
							isTimeOff: false,
							accountWorkTypeId: e.workTypeValue.toString(),
							hours: Number(e.duration.split(':')[0]),
							minutes: Number(e.duration.split(':')[1]),
							accountEmployeeId: weekViewData?.results?.viewAccountEmployees?.accountEmployeeId,
							isCopiedTimeEntry: false,
							costCenterName: e.costCenterName,
							workTypeName: e.workTypeName,
							totalTime: e.date + ' ' + e.duration + ':00'
						}
					]);
				}
			});
		}

		setModalImportOpen(false);
	};

	//Change the week to where the time entry was moved
	useEffect(() => {
		if (modalImportOpen) {
			getOutlookEvents({
				username: filterUser,
				startDate: weekViewData?.results?.periodStartDate,
				endDate: weekViewData?.results?.periodEndDate
			});
		}
	}, [modalImportOpen]);

	const tabs = [
		{
			id: 'csv',
			label: 'CSV'
		},
		{
			id: 'outlook',
			label: 'Outlook'
		}
	];

	// TABLE COMPONENT - columns for attach table
	const importTableColumns: ITableColumn<any>[] = useMemo(
		() => [
			{
				id: 'subject',
				label: 'Subject',
				width: '10%'
			},
			{
				id: 'date',
				label: 'Date',
				width: '10%'
			},
			{
				id: 'duration',
				label: 'Duration',
				width: '5%'
			},
			{
				id: 'projectName',
				label: 'Project Name',
				width: '25%'
			},
			{
				id: 'projectTask',
				label: 'Project Task',
				width: '25%'
			},
			{
				id: 'costCenter',
				label: 'Cost Center',
				width: '12%'
			},
			{
				id: 'workType',
				label: 'Work Type',
				width: '13%'
			}
		],
		[]
	);
	const stopImmediatePropagation = (e: any) => {
		e.stopPropagation();
		e.preventDefault();
	};

	const defaultOption = (
		<MenuItem
			value="default"
			disabled
			className={classes.defaultOption}
			onClick={stopImmediatePropagation}
			onKeyDown={e => e.stopPropagation()}>
			<span className={classes.defaultOptionText}>Select element</span>
		</MenuItem>
	);

	useEffect(() => {
		if (outlookEvents) {
			const countryId = weekViewData?.results?.viewAccountEmployees?.countryId;

			if (countryId == 82) {
				setOutlookEventsHelper(
					outlookEvents.map(e => ({
						...e,
						projectValue: null,
						taskValue: null,
						costCenterValue: 13, // DE -> Remote
						workTypeValue: 1,
						costCenterName: 'DE -> Remote',
						workTypeName: 'Normal'
					}))
				);
			} else if (countryId == 53) {
				setOutlookEventsHelper(
					outlookEvents.map(e => ({
						...e,
						projectValue: null,
						taskValue: null,
						costCenterValue: 12, //HR -> Remote
						workTypeValue: 1,
						costCenterName: 'HR -> Remote',
						workTypeName: 'Normal'
					}))
				);
			} else if (countryId == 232) {
				setOutlookEventsHelper(
					outlookEvents.map(e => ({
						...e,
						projectValue: null,
						taskValue: null,
						costCenterValue: 9, //UK -> Remote
						workTypeValue: 1,
						costCenterName: 'UK -> Remote',
						workTypeName: 'Normal'
					}))
				);
			} else {
				setOutlookEventsHelper(
					outlookEvents.map(e => ({
						...e,
						projectValue: null,
						taskValue: null,
						costCenterValue: 5, //PT -> Remote
						workTypeValue: 1,
						costCenterName: 'PT -> Remote',
						workTypeName: 'Normal'
					}))
				);
			}
		}
		if (outlookEvents != null && searchProjects.length != outlookEvents.length) {
			outlookEvents.forEach(() => {
				searchProjects.push('');
			});
		}
	}, [outlookEvents]);

	const handleProjectNameChange = (index, event) => {
		const newOutlookEventsHelper = [...outlookEventsHelper];
		newOutlookEventsHelper[index].projectValue = event.target.value;

		weekViewData?.results?.assignedAccountProjects.forEach(e => {
			if (e.accountProjectId == event.target.value) {
				newOutlookEventsHelper[index].projectName = e.projectName;
				newOutlookEventsHelper[index].projectCode = e.projectCode;
			}
		});
		newOutlookEventsHelper[index].taskValue = null;
		newOutlookEventsHelper[index].projectTaskName = null;
		newOutlookEventsHelper[index].projectTaskCode = null;

		setOutlookEventsHelper(newOutlookEventsHelper);
	};

	const handleProjectTaskChange = (index, event) => {
		const newOutlookEventsHelper = [...outlookEventsHelper];
		newOutlookEventsHelper[index].taskValue = event.target.value;

		weekViewData?.results?.assignedProjectTasks.forEach(e => {
			e.forEach(element => {
				if (element.id == event.target.value) {
					newOutlookEventsHelper[index].projectTaskName = element.taskName;
					newOutlookEventsHelper[index].projectTaskCode = element.taskCode;
				}
			});
		});
		setOutlookEventsHelper(newOutlookEventsHelper);
	};

	const handleCostCenterChange = (index, event) => {
		const newOutlookEventsHelper = [...outlookEventsHelper];
		newOutlookEventsHelper[index].costCenterValue = event.target.value;

		weekViewData?.results?.costCenters.forEach(e => {
			if (e.accountCostCenterId == event.target.value) {
				newOutlookEventsHelper[index].costCenterName = e.costCenterName;
			}
		});
		setOutlookEventsHelper(newOutlookEventsHelper);
	};

	const handleWorkTypeChange = (index, event) => {
		const newOutlookEventsHelper = [...outlookEventsHelper];
		newOutlookEventsHelper[index].workTypeValue = event.target.value;

		weekViewData?.results?.workTypes.forEach(e => {
			if (e.accountWorkTypeId == event.target.value) {
				newOutlookEventsHelper[index].accountWorkTypeName = e.accountWorkTypeName;
			}
		});
		setOutlookEventsHelper(newOutlookEventsHelper);
	};

	const handleSearchQuery = (index, event) => {
		const newSearchProjects = [...searchProjects];
		newSearchProjects[index] = event.target.value;

		setSearchProjects(newSearchProjects);
	};

	const changeHandlerImportTimesheet = (event: { target: { files: (string | Blob)[] } }) => {
		if (event.target.files.length > 0) {
			setSelectedFile(event.target.files[0]);
		}
	};

	const newTemplateActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => setModalImportOpen(false)
			// ,disabled: isDifferentUserOpened
		},
		{
			id: 'submit',
			label: 'Import',
			color: 'primary',
			type: 'submit',
			form: 'form-new-file-template',
			variant: 'contained',
			disabled:
				tabSelected === 1 &&
				(outlookEvents == null ||
					weekViewData?.results?.accountEmployeeTimeEntryPeriod?.submitted ||
					weekViewData?.results?.accountEmployeeTimeEntryPeriod?.approved),
			onClick: tabSelected === 0 ? onSubmitImportTimesheet : onSubmitImportOutlook
		}
	];

	const importTableData = useMemo(
		() =>
			outlookEventsHelper?.map((e, index) => ({
				...e,
				subject: e.subject,
				date: e.date,
				duration: e.duration,
				projectName: (
					<FormControl style={{ width: '250px' }}>
						<Select
							id="projectName"
							value={e.projectValue == null ? 'default' : e.projectValue}
							onChange={val => handleProjectNameChange(index, val)}>
							{defaultOption}
							<TextField
								variant="outlined"
								placeholder="Search Element"
								onClick={stopImmediatePropagation}
								onKeyDown={e => e.stopPropagation()}
								onChange={e => handleSearchQuery(index, e)}
								fullWidth
								autoFocus
								defaultValue={searchProjects[index]}
								size="small"
								margin="dense"
							/>
							{searchProjects[index]?.length === 0 &&
								weekViewData?.results?.favoriteAssignedAccountProjects?.length > 0 && (
									<ListSubheader>Recent</ListSubheader>
								)}
							{searchProjects[index]?.length === 0 &&
								weekViewData?.results?.favoriteAssignedAccountProjects?.length > 0 &&
								weekViewData?.results?.favoriteAssignedAccountProjects
									.filter(e => !XTRACKER_ABSENCE_PROJECTS.includes(e.accountProjectId))
									.map(project => (
										<MenuItem key={project.accountProjectId} value={project.accountProjectId}>
											{project.projectName}
										</MenuItem>
									))}
							{searchProjects[index]?.length === 0 &&
								weekViewData?.results?.favoriteAssignedAccountProjects?.length > 0 && (
									<ListSubheader>Other Projects</ListSubheader>
								)}
							{searchProjects[index]?.length === 0 &&
								weekViewData?.results?.assignedAccountProjects
									.filter(
										e =>
											!XTRACKER_ABSENCE_PROJECTS.includes(e.accountProjectId) &&
											!weekViewData.results.favoriteAssignedAccountProjects.some(
												fav => fav.accountProjectId === e.accountProjectId
											)
									)
									.map(project => (
										<MenuItem key={project.accountProjectId} value={project.accountProjectId}>
											{project.projectName}
										</MenuItem>
									))}
							{searchProjects[index]?.length > 0 &&
								weekViewData?.results?.assignedAccountProjects
									.filter(e =>
										e?.projectName
											?.toLowerCase()
											.normalize('NFD') // Decomposes characters (e.g., é -> e + ́)
											.replace(/[\u0300-\u036f]/g, '') // Removes diacritical marks
											.includes(
												searchProjects[index]
													?.toLowerCase()
													.normalize('NFD')
													.replace(/[\u0300-\u036f]/g, '')
											)
									)
									.map(project => (
										<MenuItem key={project.accountProjectId} value={project.accountProjectId}>
											{project.projectName}
										</MenuItem>
									))}
						</Select>
					</FormControl>
				),
				projectTask: (
					<FormControl style={{ width: '250px' }}>
						<Select
							id="projectTask"
							value={e.taskValue == null ? 'default' : e.taskValue}
							onChange={val => {
								handleProjectTaskChange(index, val);
							}}>
							{defaultOption}
							{weekViewData?.results?.assignedProjectTasks
								?.filter(element => element[0].accountProjectId == e.projectValue)
								?.flatMap(element =>
									element.map(task => (
										<MenuItem key={task.id} value={task.id}>
											{task.taskName}
										</MenuItem>
									))
								)}
						</Select>
					</FormControl>
				),
				costCenter: (
					<FormControl style={{ width: '150px' }}>
						<Select
							id="costCenter"
							value={e.costCenterValue == null ? 'default' : e.costCenterValue}
							onChange={val => {
								handleCostCenterChange(index, val);
							}}>
							{defaultOption}
							{weekViewData?.results?.costCenters?.map(center => (
								<MenuItem key={center.accountCostCenterId} value={center.accountCostCenterId}>
									{center.accountCostCenterName}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				),
				workType: (
					<FormControl style={{ width: '150px' }}>
						<Select
							id="workType"
							value={e.workTypeValue == null ? 'default' : e.workTypeValue}
							onChange={val => {
								handleWorkTypeChange(index, val);
							}}>
							{defaultOption}
							{weekViewData?.results?.workTypes.map(type => (
								<MenuItem key={type.accountWorkTypeId} value={type.accountWorkTypeId}>
									{type.accountWorkTypeName}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				)
			})),
		[outlookEventsHelper, weekViewData, searchProjects]
	);

	return (
		<Dialog
			maxWidth="xl"
			modal={{
				open: Boolean(modalImportOpen),
				handleClose: (event: unknown, reason: string) => {
					if (reason !== 'backdropClick') setModalImportOpen(false);
				},
				content: (
					<Grid item xs={12}>
						<TabHeader
							selected={tabSelected}
							handleSelected={(event: React.SyntheticEvent<Element, Event>, newValue: number) =>
								setTabSelected(newValue)
							}
							variant="scrollable"
							scrollButtons="auto">
							{tabs.map((tab, index) => (
								<Tab key={`Resume_${tab.id}_tab_button`} label={tab.label} index={index} />
							))}
						</TabHeader>
						{tabs.map((tab, index) => (
							<TabPanel key={`Resume_${tab.id}_tab_content`} value={tabSelected} index={index}>
								<Grid
									container
									direction="column"
									justifyContent="space-evenly"
									alignItems="center"
									spacing={5}>
									{tabSelected === 0 && (
										<form encType="multipart/form-data" id="form-new-file-template">
											<Grid container style={{ padding: '40px' }}>
												<Grid item xs={12}>
													<InfoField label="" value="Select a file to upload" />
													<Input
														onChange={
															changeHandlerImportTimesheet as unknown as ChangeEventHandler
														}
														name="file"
														type="file"
													/>
												</Grid>
												<div>
													<Typography align="justify" style={{ marginTop: '30px' }}>
														<span style={{ fontWeight: 'bold' }}>
															You can download the following template to facilitate the
															import:{' '}
														</span>
														<a
															style={{
																color: 'blue',
																cursor: 'pointer',
																textDecoration: 'underline'
															}}
															onClick={e => downloadImportTemplate()}>
															Template
														</a>
													</Typography>

													<Typography
														gutterBottom
														align="justify"
														style={{ marginTop: '10px' }}>
														<span style={{ color: 'red', fontWeight: 'bold' }}>Notes:</span>
													</Typography>

													<Typography gutterBottom align="justify">
														The modifications should be confirmed by pressing{' '}
														<span
															style={{
																fontWeight: 'bold',
																textDecoration: 'underline'
															}}>
															Save
														</span>{' '}
														button,{' '}
														<span
															style={{
																fontWeight: 'bold',
																textDecoration: 'underline'
															}}>
															after import.
														</span>
													</Typography>

													<Typography align="justify">
														Please{' '}
														<span
															style={{
																fontWeight: 'bold',
																textDecoration: 'underline'
															}}>
															use a notepad
														</span>{' '}
														application to{' '}
														<span
															style={{
																fontWeight: 'bold',
																textDecoration: 'underline'
															}}>
															open the file
														</span>
														. We do not recommend you use Excel as it might affect your data
														and the import result.
													</Typography>
												</div>
											</Grid>
										</form>
									)}
									{tabSelected === 1 &&
										(outlookEvents == null ? (
											<CircularProgress color="primary" style={{ marginTop: '40px' }} />
										) : (
											<Grid container style={{ padding: '40px' }}>
												<Table
													tableData={importTableData}
													columns={importTableColumns}
													defaultSortColumn="start"
												/>
												<div>
													<Typography
														gutterBottom
														align="justify"
														style={{ marginTop: '10px' }}>
														<span style={{ color: 'red', fontWeight: 'bold' }}>Notes:</span>
													</Typography>
													<Typography gutterBottom align="justify">
														Only events with Project and Task selected will be imported.
													</Typography>
													<Typography gutterBottom align="justify">
														There is no limit to the number of times you can import an
														event.
													</Typography>
												</div>
											</Grid>
										))}
								</Grid>
							</TabPanel>
						))}
					</Grid>
				)
			}}
			title="Import Timesheet"
			actions={newTemplateActions}
			scroll="body"
		/>
	);
};
export default ImportModal;
