/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
//* EXTERNAL LIBS
import React, { FC, useState, useEffect, useMemo, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import generator from 'generate-password-browser';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

//* EXTERNAL LIBS --> MUI
import { Check, Clear, PriorityHigh } from '@mui/icons-material';
import { Typography } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, ActionFooter, TabPanel, FormSteps, Dialog } from 'xpand-ui/core';
import { ErrorPage } from 'xpand-ui/lab';

//* TYPINGS
import {
	ICollaborationProposal,
	ICompanyInfo,
	IProposalFormCareerHelper,
	IProposalPayload,
	IProposalProject
} from 'typings/store/admin/proposals';
import { ICreateEmployeeData } from 'typings/store/admin/admissions';
import { OriginalResponse } from 'lib/handlers/requestTypes';
import { IChoosableBaseInfo, FetchedData, Match } from 'typings/store/generalTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';
import { addNotification } from 'lib/utils/notifications';
import { isTouchedFieldsUsed } from 'lib/utils/formUtils';

//*
import General from 'pages/Admin/_ProposalsFormUtils/General';
import CareerFinancial from 'pages/Admin/_ProposalsFormUtils/CareerFinancial';
import OtherBenefits from 'pages/Admin/_ProposalsFormUtils/OtherBenefits';
import ContractCompany from 'pages/Admin/_ProposalsFormUtils/ContractCompany';
import Projects from 'pages/Admin/_ProposalsFormUtils/Projects';
import Summary from 'pages/Admin/_ProposalsFormUtils/Summary';
import {
	getInitialFoodAllowanceValues,
	proposalsGetSelectOptions,
	proposalsGetFixedSelectOptions,
	updateSelectsInCascade,
	getDefaultProjectsList,
	setValuesFromProposal,
	getProposalApiMethods,
	getMissingFieldsNames,
	getProposalSummaryInfoHelper
} from 'pages/Admin/_ProposalsFormUtils/utils';
import { EMPLOYEE } from 'pages/Admin/_ProposalsFormUtils/constants';

//* LOCAL COMPONENT IMPORTS
import { Prompt } from 'react-router';
import { schema, createDefaultValues } from './yupSchema';
import { useStyles } from './styles';
import { CreateEmployeeProps } from '.';
import { XPAND_CONTRACTOR_ID, XPAND_CONTRACTOR_ID_HR } from 'lib/utils/constants';
import { getLSField } from 'lib/utils/cookies';
import { getFormFieldsConfig } from 'store/administration/formFields/thunks';
import { useDispatch, useSelector } from 'react-redux';
import { name } from '@azure/msal-browser/dist/packageMetadata';

//* COMPONENT INTERFACES
interface ICreateEmployee extends CreateEmployeeProps {
	match: Match<{
		id: string;
	}>;
	goToPage: (path: string) => void;
}

//* COMPONENT
const CreateEmployee: FC<ICreateEmployee> = ({
	match,
	goToPage,
	// ? redux fields
	proposals,
	admissions,
	// ? redux thunks
	// new
	getCreateEmployee,
	// submit new
	submitCreateEmployee,
	// HELPERS %%%%%%%%%%%%%%%%
	// calculate annual gross
	getProposalAnnualGrossValue,
	clearNewEmployeeForm,
	// calculate  irs
	getIrsValue,
	clearAdmissionsError,
	company
}) => {
	const classes = useStyles();
	const [confirmSubmitCreateEmployee, setConfirmCreateEmployee] = useState(false);
	const { loading, proposalAnnualGross, irsValue } = proposals;
	const formFields = useSelector(state => state.formFields.formFieldsConfig);
	const { defaultCompanyKey, companyDomain } = company;
	const { loading: loadingAdm, newEmployee, admissionAction, error } = admissions;
	const { params } = match;
	const pageId = params?.id;
	const scrollToTopRef = useRef<HTMLDivElement | any>(null);
	const scrollbarRef = React.createRef();
	const [tabSelected, setTabSelected] = useState(0);
	const dispatch = useDispatch();
	const [tabs, setTabs] = useState([
		{
			id: 0,
			label: 'General'
		},
		{
			id: 1,
			label: 'Career & Financial'
		},
		{
			id: 2,
			label: 'Other Benefits'
		},
		{
			id: 3,
			label: 'Contract & Company'
		},
		{
			id: 4,
			label: 'Projects'
		},
		{
			id: 5,
			label: 'Summary'
		}
	]);

	//Function used when user uses filters in the searchableSelect in career financial that has the job role levels
	const tableFilterSearch = (array, searchValue) => {
		if (searchValue == '') return array;
		if (searchValue == 'favorite')
			return array.filter(
				row => row.id !== watch('careerJobRoleLevel') && row.preferable && row.isEmpty !== true
			);
	};

	//Filters available in career job role levels table
	const filters = [
		{
			name: 'Show only favorite',
			value: 'favorite'
		}
	];

	useEffect(() => {
		setValue('otherbenefits', currentDefaultValues?.otherbenefits);
		setValue('otherBenefitsList', currentDefaultValues?.otherBenefitsList);
	}, [currentDefaultValues]);

	useEffect(() => {
		if (scrollbarRef.current) {
			scrollbarRef.current.scrollToTop();
		}
	}, [tabSelected]);

	useEffect(() => {
		scrollToTopRef?.current?.scrollIntoView(0, 0);
	});

	//Forces to update of the value "company" to the current company
	useEffect(() => {
		setValue('company', defaultCompanyKey);
	}, []);

	useEffect(() => {}, [formFields]);

	const [formWarnings, setFormWarnings] = useState<Array<number>>([]);
	const [errorCheck, setErrorCheck] = useState(false);
	const [submitResult, setSubmitResult] = useState<OriginalResponse | null>(null);
	const [selectHelper, setSelectHelper] = useState<IProposalFormCareerHelper>({
		company: defaultCompanyKey,
		businessUnit: currentDefaultValues?.businessUnit,
		division: currentDefaultValues?.division,
		department: currentDefaultValues?.department
	});
	//Has the initial loaded date
	const [originalDate, setOriginalDate] = useState('');
	// Create DefaultValues
	const [currentDefaultValues, setCurrentDefaultValues] = useState(() =>
		createDefaultValues(defaultCompanyKey ? defaultCompanyKey : 'PT')
	);

	const selectedCompany = useMemo(() => {
		if (!newEmployee || newEmployee?.code) {
			return undefined;
		}
		const admissionCompany = (newEmployee as ICreateEmployeeData)?.admission?.company || selectHelper.company;

		return selectHelper.company
			? (newEmployee as ICreateEmployeeData).companies.find(company => company.searchKey === admissionCompany)
			: undefined;
	}, [newEmployee, selectHelper.company]);

	const referenceData = useMemo(() => selectedCompany?.referenceData || null, [selectedCompany]);

	// UseEffect required to adjust the schemas and defaultValues whenever the company changes
	useEffect(() => {
		if (formFields && referenceData) {
			setValue('commsPlafond', referenceData.xphrMonStdComPlafond);
			setValue(
				'healthInsuranceValue',
				newEmployee?.proposal?.healthInsuranceValue || referenceData?.xphrMonStdHeaInsCost
			);
			setCurrentDefaultValues(createDefaultValues(formFields?.formFieldsConfig?.country, referenceData));
		}
	}, [formFields, referenceData]);

	const {
		handleSubmit,
		control,
		formState: { errors, touchedFields },
		reset,
		watch,
		setValue,
		getValues,
		clearErrors,
		trigger,
		resetField
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema(formFields?.formFieldsConfig)),
		context: { formFields: formFields?.formFieldsConfig },
		reValidateMode: 'onChange',
		defaultValues: currentDefaultValues,
		shouldUnregister: false
	});

	const [isContractor, setIsContractor] = useState('Employee');

	const [initialBaseValue, setInitialBaseValue] = useState(watch().baseValue);

	useEffect(() => {
		if (!error && admissionAction && !submitResult) {
			admissionAction?.data?.results && setSubmitResult(admissionAction as OriginalResponse);
		}
	}, [admissionAction]);

	useEffect(() => {
		if (submitResult?.data?.results) {
			clearNewEmployeeForm();
			goToPage('/admin/admissions/manageAdmissions');
		}
	}, [submitResult]);

	//Loads the form format fields
	useEffect(() => {
		dispatch(getFormFieldsConfig(isContractor, watch('company'), 'New Employee'));
	}, [newEmployee, isContractor, watch('company')]);

	/** //* GET API CALCULATIONS HELPERS
	 * @function initFinancialValues CALCULATE TAXABLE / GROSS VALUES / TWELFTHS
	 * @function updateIRSTax CALCULATE IRS
	 * @function getAnnualGrossValue
	 * @function getCareerFields CAREER NAMES HELPERS
	 */
	const { initFinancialValues, updateIRSTax, getAnnualGrossValue, getCareerFields } = getProposalApiMethods(
		reset,
		getIrsValue,
		getProposalAnnualGrossValue
	);

	useEffect(() => {
		if (Object.keys(errors).length > 0 && errorCheck) {
			const { names } = getMissingFieldsNames(errors, isContractor);
			addNotification('danger', `The following required fields are required: ${names.join(', ')}`, 5);
			setErrorCheck(false);
		}
	}, [errorCheck, errors]);

	/** Set food Allowance value when foodAllowance type changes
	 * @onchange of the following fields
	 * @field1 - foodAllowanceType
	 */
	const updateFoodAllowanceValue = useMemo(
		() => (data: IProposalPayload) => {
			if (referenceData) {
				const { foodAllowanceType, foodAllowance } = data;

				setValue(
					'foodAllowance',
					`${
						foodAllowanceType === 'Cash'
							? foodAllowance || referenceData.xphrFoodAllowValue
							: foodAllowance || referenceData.xphrCrFoodAllowValue
					}`
				);
			}
		},
		[referenceData]
	);
	const updateFormAfterLoad = (admissionPayload: ICreateEmployeeData) => {
		const { sheet, admission, proposal } = admissionPayload;
		if (proposal) {
			// update individually each field with the new data
			const { id, token, collaborationType, email, ...propToLoad } = proposal;
			setValuesFromProposal({ ...propToLoad } as ICollaborationProposal, reset, watch, true);

			// assign helpers values
			setSelectHelper({
				company: proposal.company,
				businessUnit: proposal.businessUnit,
				division: proposal.division,
				department: proposal.department,
				startDate: proposal.startDate,
				careerPosition: proposal.careerPosition,
				careerJobRole: proposal.careerJobRole,
				careerJobRoleLevel: proposal.careerJobRoleLevel,
				holdersNumber: proposal.holdersNumber as string | null
			});

			const payload = watch();

			if (payload.prizeBonus === '0.00') {
				setValue('eligiblePrizeValue', 0, { shouldValidate: true });
				setValue('prizeBonusPercentage', 0, { shouldValidate: true });
				setValue('prizeBonusGoals', [], { shouldValidate: true });
			} else {
				setValue(
					'eligiblePrizeValue',
					(Number(payload.prizeBonus) * Number(payload.monthsApplicable)) / 12 || 0,
					{
						shouldValidate: true
					}
				);
				setValue(
					'prizeBonusPercentage',
					(Number(payload.prizeBonus) / (Number(payload.prizeBonus) + Number(payload.annualGrossValue))) *
						100 || 0,
					{ shouldValidate: true }
				);
			}

			// Set tax related fields if they were sent
			if (proposal.holdersNumber) {
				setValue('holdersNumber', proposal.holdersNumber as string, { shouldValidate: false });
			}
			if (proposal.dependentsNumber) {
				setValue('dependentsNumber', proposal.dependentsNumber as string, { shouldValidate: false });
			}
			if (proposal.disabled) {
				setValue('disabled', proposal.disabled, { shouldValidate: false });
			}
			if (proposal.maritalStatus) {
				setValue('maritalStatus', proposal.maritalStatus, { shouldValidate: false });
			}

			// load api related fields after form is filled
			initFinancialValues(watch());
			updateIRSTax(watch());
			getAnnualGrossValue(watch());
			updateFoodAllowanceValue(watch());
			setInitialBaseValue(watch().baseValue);
		}
		if (sheet) {
			if (sheet.holdersNumber) {
				setValue('holdersNumber', sheet.holdersNumber as string, { shouldValidate: false });
			}
			if (sheet.dependentsNumber) {
				setValue('dependentsNumber', sheet.dependentsNumber as unknown as string, { shouldValidate: false });
			}
			if (sheet.disabled) {
				setValue('disabled', sheet.disabled, { shouldValidate: false });
			}
			if (sheet.maritalStatus) {
				setValue('maritalStatus', sheet.maritalStatus, { shouldValidate: false });
			}
		}
		if (admission && !proposal) {
			setSelectHelper({
				company: admission.company
			});
			if (admission.admissionStartDate) {
				setValue('startDate', admission.admissionStartDate, { shouldValidate: false });
			}
		}

		if (admission?.company == 'HR' || proposal?.company == 'HR') {
			setValue(
				'payBonusSalary',
				proposal?.payBonusSalary != undefined ? proposal?.payBonusSalary : currentDefaultValues.payBonusSalary
			);
		}
	};

	const genPassword = () => {
		const pwd = generator.generate({
			length: 14,
			lowercase: true,
			uppercase: true,
			numbers: true,
			symbols: true,
			strict: true,
			exclude: '«»çÇ€£§´`^~'
		});
		setValue('password', pwd);
	};

	//* USE EFFECT TO LOAD INITIAL DATA
	useEffect(() => {
		if (newEmployee === null) {
			// load data on new
			getCreateEmployee(pageId as string);
		} else if (newEmployee && selectedCompany?.referenceData) {
			// eslint-disable-next-line no-console
			genPassword();
			const formData = getValues();
			const defaultProjects = getDefaultProjectsList(
				selectedCompany.searchKey as string,
				(newEmployee as ICreateEmployeeData).proposal?.division as string,
				(newEmployee as ICreateEmployeeData).defaultProjects,
				(newEmployee as ICreateEmployeeData).defaultDivisionProjects
			);
			reset({
				...formData,
				...getInitialFoodAllowanceValues(selectedCompany.referenceData),
				name: (newEmployee as ICreateEmployeeData).sheet?.fullName,
				admissionProcessSheetId: (newEmployee as ICreateEmployeeData).sheet?.id || undefined,
				usersList: (newEmployee as ICreateEmployeeData).users.map(e => e.username),
				emailsList: (newEmployee as ICreateEmployeeData).users.map(e => e.email),
				fullName: (newEmployee as ICreateEmployeeData).sheet?.fullName || '',
				company: selectedCompany.searchKey || '',
				companyName: selectedCompany.name || '',
				// FIXME
				projects:
					((newEmployee as ICreateEmployeeData).projects as IChoosableBaseInfo[])
						?.filter(e => defaultProjects.includes(e.name))
						.map(e => ({ projectId: e.id, projectName: e.name })) || []
			});

			if (
				((newEmployee as ICreateEmployeeData)?.proposal?.employeeCategory as string) ===
				(XPAND_CONTRACTOR_ID || BALWURK_CONTRACTOR_ID)
			) {
				// update the form selects and remake the calculations API requests
				setValue('hourlyRate', (newEmployee as ICreateEmployeeData)?.proposal?.budget / 8 / 18, {
					shouldValidate: true
				});
				setValue('budget', (newEmployee as ICreateEmployeeData)?.proposal?.budget, { shouldValidate: true });
				setIsContractor('Contractor');
			}

			updateFormAfterLoad(newEmployee as ICreateEmployeeData);
		}
		setOriginalDate(getValues('startDate'));
	}, [newEmployee, referenceData]);

	//UPDATES NECESSARY INFORMATION TO HR/PT CONTRACTOR/EMPLOYEE WORK AFTER YUPSCHEMA UPDATE
	useEffect(() => {
		if (watch('company') == 'HR') {
			if (newEmployee?.admission?.employeeCategory == XPAND_CONTRACTOR_ID) {
				setValue('employeeCategory', XPAND_CONTRACTOR_ID_HR);
				setValue('collaborationRole', 'CONTRACTOR');
			}
			if (!newEmployee?.admission) {
				setValue('commsPlafondFlag', true);
				setValue('commsPlafond', currentDefaultValues.commsPlafond);
				setValue('otherbenefits', currentDefaultValues.otherbenefits);
				setValue('otherBenefitsList', currentDefaultValues.otherBenefitsList);
			}
			setValue('bonusSalary', watch('baseValue'));
			setValue('companySocSec', 0);
			setValue('hasTwelfths', false);
			setValue('foodAllowanceDays', 0);
			setValue('foodAllowance', 0);
		} else {
			if (newEmployee?.admission?.employeeCategory == XPAND_CONTRACTOR_ID) {
				setValue('employeeCategory', XPAND_CONTRACTOR_ID);
				setValue('collaborationRole', 'CONTRACTOR');
			}
			if (!newEmployee?.admission) {
				setValue('commsPlafondFlag', true);
				setValue('commsPlafond', currentDefaultValues.commsPlafond);
			}
		}
	}, [currentDefaultValues, formFields]);
	//* USE EFFECT TO UPDATE FORM WHEN THE IRS IS CALCULATED
	useEffect(() => {
		if (irsValue) {
			setValue('irsToApply', irsValue.irs as string, { shouldValidate: true });
			setValue('annualPrizeIRS', irsValue.irsPrize as string, { shouldValidate: true });
			setValue('irsTwelfth', irsValue.irsTwelfth as string, { shouldValidate: true });
		}
	}, [irsValue]);

	//* USE EFFECT TO UPDATE ANNUAL GROSS VALUE
	useEffect(() => {
		if (proposalAnnualGross) {
			const currentForm = watch();
			const { annualGrossValue } = proposalAnnualGross;
			setValue('annualGrossValue', annualGrossValue as number, { shouldValidate: true });
			setValue(
				'prizeBonusPercentage',
				(Number(currentForm.prizeBonus) / (Number(currentForm.prizeBonus) + Number(annualGrossValue))) * 100 ||
					0,
				{ shouldValidate: true }
			);
		}
	}, [proposalAnnualGross]);

	// ? SUBMIT PROPOSAL
	const onSubmit = (data: IProposalPayload) => {
		const submitPayload = { ...data };
		delete submitPayload.usersList;
		delete submitPayload.emailsList;
		submitPayload.email = `${submitPayload.email}@${companyDomain}`;
		submitPayload.formFields = formFields.formFieldsConfig;
		if (data.company == 'PT') {
			if (isContractor === 'Contractor') {
				submitPayload.commsPlafond = 0;
				submitPayload.commsPlafondFlag = false;
				submitPayload.foodAllowance = 0;
				submitPayload.foodAllowanceDays = 0;
				submitPayload.hasTwelfths = false;
				submitPayload.healthInsuranceCheck = false;
				submitPayload.healthInsuranceValue = 0;
				submitPayload.ihtPercentage = 0;
				submitPayload.monthsApplicable = 0;
				submitPayload.otherbenefits = false;
				submitPayload.otherBenefitsList = [];
			}
		}

		submitCreateEmployee(pageId, submitPayload, (newEmployee as ICreateEmployeeData).admission.token);
	};

	// SELECT FIELDS DEPENDENT OF COMPANY - CAREER NAMES
	const careerFields = useMemo(() => {
		const payload = getCareerFields(selectedCompany, selectHelper);

		if (selectHelper.department && payload.careerPosition?.length === 0) {
			addNotification('warning', 'There are no Careers for this department', 5);
		}
		return payload;
	}, [newEmployee, selectedCompany, selectHelper]);

	// SELECT FIELDS DEPENDENT OF COMPANY
	const selectOptions = useMemo(
		() =>
			newEmployee &&
			!newEmployee.code &&
			proposalsGetSelectOptions(
				classes,
				newEmployee as FetchedData,
				selectHelper,
				selectedCompany as ICompanyInfo
			),
		[newEmployee, selectedCompany, selectHelper]
	);
	// FIXED SELECT FIELDS
	const selectFixedOptions = useMemo(
		() => newEmployee && proposalsGetFixedSelectOptions(newEmployee as ICreateEmployeeData),
		[newEmployee]
	);

	// UPDATE SELECT CHILD'S IN CASCADE WHEN NEEDED
	const handleSelectHelper = (field: string, value: string | null) => {
		updateSelectsInCascade(setSelectHelper, setValue, field, value);
		if (field === 'division') {
			const defaultProjects = getDefaultProjectsList(
				selectedCompany?.searchKey as string,
				value as string,
				(newEmployee as ICreateEmployeeData)?.defaultProjects,
				(newEmployee as ICreateEmployeeData)?.defaultDivisionProjects
			);
			setValue(
				'projects',
				(newEmployee as ICreateEmployeeData)?.projects
					?.filter(e => defaultProjects.includes(e.name))
					.map(e => ({ projectId: e.id, projectName: e.name })) as IProposalProject[]
			);
		}
	};

	// PAGE FOOTER ACTIONS
	const footerActions = useMemo(
		() => [
			{
				id: 'cancel',
				label: 'Cancel',
				left: true,
				onClick: () => {
					clearNewEmployeeForm();
					goToPage('/admin/admissions/manageAdmissions');
				},
				variant: 'outlined',
				disabled: false
			},
			...(tabSelected !== 0
				? [
						{
							id: 'previous',
							label: 'Previous',
							// left: true,
							onClick: () => {
								setTabSelected(tabSelected - 1);
								scrollToTopRef.current.scrollIntoView({
									behavior: 'smooth',
									block: 'start'
								});
							},
							variant: 'outlined',
							disabled: false
						}
				  ]
				: []),
			...(tabSelected !== tabs.length - 1
				? [
						{
							id: 'next',
							label: 'Next',
							onClick: async () => {
								setTabSelected(tabSelected + 1);
								/* Checking if the tabSelected is in the formWarnings array. If it is, it is removing it from
								the array. */
								if (formWarnings.includes(tabSelected)) {
									setFormWarnings(formWarnings.filter(e => e !== tabSelected));
								}
								scrollToTopRef.current.scrollIntoView({
									behavior: 'smooth',
									block: 'start'
								});
								await trigger('prizeBonusGoals');
							},
							variant: 'contained',
							disabled: false
						}
				  ]
				: []),
			...(tabSelected === tabs.length - 1
				? [
						{
							id: 'submit',
							label: 'Submit',
							onClick: () => {
								setConfirmCreateEmployee(true);
							},
							variant: 'contained',
							disabled: getLSField('impersonate_userInfo')
						}
				  ]
				: [])
		],
		[tabSelected]
	);

	if (newEmployee && newEmployee?.code) {
		return (
			<ErrorPage
				code={newEmployee?.code}
				message={(newEmployee as OriginalResponse)?.payload.message}
				exit={() => clearNewEmployeeForm()}
			/>
		);
	}

	let hasBaseValueChanges = false;
	if (watch().baseValue !== initialBaseValue) {
		hasBaseValueChanges = true;
	}

	if (error) return handleErrorPage(error, clearAdmissionsError);

	const isLoading = newEmployee === null || selectedCompany === null;
	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{(loading || loadingAdm) && <LoadingOverlay />}
			<div className={classes.root}>
				{/* PROPOSALS FORM */}
				<form
					ref={scrollToTopRef}
					id="form-new-employee"
					className={classes.pageForm}
					onSubmit={e => {
						e.preventDefault();
						clearErrors();
						setErrorCheck(true);
						setConfirmCreateEmployee(false);
						return handleSubmit(() => onSubmit(watch()))(e);
					}}>
					<FormSteps steps={tabs} selected={tabSelected} errors={formWarnings} />
					<Scrollbars
						ref={scrollbarRef}
						style={{ height: 'calc(100vh - 240px)', overflowX: 'hidden' }}
						renderTrackHorizontal={(props: any) => (
							<div {...props} style={{ display: 'none' }} className="track-horizontal" />
						)}>
						{tabs.map(tab => {
							const commonProps = {
								type: EMPLOYEE,
								selectOptions: {
									...selectOptions,
									...careerFields,
									...selectFixedOptions
								},
								currentDefaultValues,
								referenceData,
								getAnnualGrossValue,
								initFinancialValues,
								updateFoodAllowanceValue,
								populateFormWithProposal: () => ({}),
								handleSelectHelper,
								// react-hook-form helpers
								control,
								errors,
								reset,
								resetField,
								getValues,
								setValue,
								watch,
								clearErrors
							};

							return (
								<>
									{isContractor !== 'Contractor' && watch('company') == 'PT' ? (
										<TabPanel
											key={`newEmployee_tab_container_${tab.id}`}
											value={tabSelected}
											index={tab.id}>
											{tab.id === 0 && (
												<General
													{...commonProps}
													{...{ updateIRSTax }}
													{...{ isContractor }}
													{...{ setIsContractor }}
													{...{ initFinancialValues }}
													{...{ updateIRSTax }}
													{...{ getAnnualGrossValue }}
													{...{ updateFoodAllowanceValue }}
													{...{ setInitialBaseValue }}
													{...{ setTabs }}
													{...{ formFields }}
													{...{ isContractor }}
													{...{ companyDomain }}
												/>
											)}
											{tab.id === 1 && (
												<CareerFinancial
													{...commonProps}
													{...{ updateIRSTax }}
													{...{ newEmployee }}
													{...{ isContractor }}
													{...{ formFields }}
													{...{ tableFilterSearch }}
													{...{ filters }}
												/>
											)}
											{tab.id === 2 &&
												isContractor !== 'Contractor' &&
												watch('company') == 'PT' && (
													<OtherBenefits
														{...commonProps}
														{...{ updateIRSTax }}
														{...{ formFields }}
													/>
												)}
											{tab.id === 3 && (
												<ContractCompany
													{...commonProps}
													genPassword={genPassword}
													{...{ isContractor }}
													{...{ formFields }}
												/>
											)}
											{tab.id === 4 && <Projects {...commonProps} />}
											{tab.id === 5 && (
												<Summary
													additionalErrors={errors}
													handleWarning={(names: unknown, tabsWithWarn: number[]) =>
														setFormWarnings(tabsWithWarn)
													}
													{...commonProps}
													{...{ isContractor }}
													{...{ formFields }}
													isCollabType={false}
												/>
											)}
										</TabPanel>
									) : isContractor !== 'Contractor' && watch('company') == 'HR' ? (
										<TabPanel
											key={`newEmployee_tab_container_${tab.id}`}
											value={tabSelected}
											index={tab.id}>
											{tab.id === 0 && (
												<General
													{...commonProps}
													{...{ updateIRSTax }}
													{...{ isContractor }}
													{...{ setIsContractor }}
													{...{ initFinancialValues }}
													{...{ updateIRSTax }}
													{...{ getAnnualGrossValue }}
													{...{ updateFoodAllowanceValue }}
													{...{ setInitialBaseValue }}
													{...{ setTabs }}
													{...{ formFields }}
													{...{ isContractor }}
													{...{ companyDomain }}
												/>
											)}
											{tab.id === 1 && (
												<CareerFinancial
													{...commonProps}
													{...{ updateIRSTax }}
													{...{ newEmployee }}
													{...{ isContractor }}
													{...{ formFields }}
													{...{ tableFilterSearch }}
													{...{ filters }}
												/>
											)}
											{tab.id === 2 && (
												<OtherBenefits
													{...commonProps}
													{...{ updateIRSTax }}
													{...{ formFields }}
												/>
											)}
											{tab.id === 3 && (
												<ContractCompany
													{...commonProps}
													genPassword={genPassword}
													{...{ isContractor }}
													{...{ formFields }}
												/>
											)}
											{tab.id === 4 && <Projects {...commonProps} />}
											{tab.id === 5 && (
												<Summary
													additionalErrors={errors}
													handleWarning={(names: unknown, tabsWithWarn: number[]) =>
														setFormWarnings(tabsWithWarn)
													}
													{...commonProps}
													{...{ isContractor }}
													{...{ formFields }}
													isCollabType={false}
												/>
											)}
										</TabPanel>
									) : isContractor === 'Contractor' && watch('company') == 'PT' ? (
										<TabPanel
											key={`newEmployee_tab_container_${tab.id}`}
											value={tabSelected}
											index={tab.id}>
											{tab.id === 0 && (
												<General
													{...commonProps}
													{...{ updateIRSTax }}
													{...{ setIsContractor }}
													{...{ setTabs }}
													{...{ isContractor }}
													{...{ formFields }}
													{...{ companyDomain }}
												/>
											)}
											{tab.id === 1 && (
												<CareerFinancial
													{...commonProps}
													{...{ updateIRSTax }}
													{...{ newEmployee }}
													{...{ isContractor }}
													{...{ formFields }}
													{...{ tableFilterSearch }}
													{...{ filters }}
												/>
											)}
											{tab.id === 2 && (
												<ContractCompany
													{...commonProps}
													genPassword={genPassword}
													{...{ isContractor }}
													{...{ formFields }}
												/>
											)}
											{tab.id === 3 && <Projects {...commonProps} />}
											{tab.id === 4 && (
												<Summary
													additionalErrors={errors}
													handleWarning={(names: unknown, tabsWithWarn: number[]) =>
														setFormWarnings(tabsWithWarn)
													}
													{...commonProps}
													{...{ isContractor }}
													{...{ formFields }}
													isEmpCat={false}
												/>
											)}
										</TabPanel>
									) : (
										<TabPanel
											key={`newEmployee_tab_container_${tab.id}`}
											value={tabSelected}
											index={tab.id}>
											{tab.id === 0 && (
												<General
													{...commonProps}
													{...{ updateIRSTax }}
													{...{ setIsContractor }}
													{...{ setTabs }}
													{...{ isContractor }}
													{...{ formFields }}
													{...{ companyDomain }}
												/>
											)}
											{tab.id === 1 && (
												<CareerFinancial
													{...commonProps}
													{...{ updateIRSTax }}
													{...{ newEmployee }}
													{...{ isContractor }}
													{...{ formFields }}
													{...{ tableFilterSearch }}
													{...{ filters }}
												/>
											)}
											{tab.id === 2 && (
												<ContractCompany
													{...commonProps}
													genPassword={genPassword}
													{...{ isContractor }}
													{...{ formFields }}
												/>
											)}
											{tab.id === 3 && <Projects {...commonProps} />}
											{tab.id === 4 && (
												<Summary
													additionalErrors={errors}
													handleWarning={(names: unknown, tabsWithWarn: number[]) =>
														setFormWarnings(tabsWithWarn)
													}
													{...commonProps}
													{...{ isContractor }}
													{...{ formFields }}
													isEmpCat={false}
												/>
											)}
										</TabPanel>
									)}
								</>
							);
						})}
					</Scrollbars>
				</form>
			</div>
			<Dialog
				modal={{
					open: Boolean(confirmSubmitCreateEmployee),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setConfirmCreateEmployee(false);
					},
					content: <Typography>Are you sure you want to submit?</Typography>
				}}
				title="Confirm submission"
				actions={[
					{
						id: 'cancel',
						label: 'Cancel',
						color: 'secondary',
						variant: 'text',
						onClick: () => {
							setConfirmCreateEmployee(false);
						}
					},
					{
						id: 'submit',
						label: 'Confirm',
						color: 'primary',
						variant: 'contained',
						onClick: () => {},
						type: 'submit',
						form: 'form-new-employee'
					}
				]}
				scroll="body"
			/>
			<ActionFooter actions={footerActions} />
			<Prompt
				when={submitResult == null && (isTouchedFieldsUsed(touchedFields, getValues) || hasBaseValueChanges)}
				message="Are you sure you want to leave without saving?"
			/>
		</>
	);
};

export default withLayout(CreateEmployee);
