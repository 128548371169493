import { restCall } from 'lib/handlers/restHandler';
import { OriginalResponse, Success, GET, POST, PUT, EXPORTFILE, DELETE } from 'lib/handlers/requestTypes';
import { INewEmailTemplate, IGrantAccessControl, IReleaseNotesEmail } from 'typings/store/admin/administration';

export const fetchEmailTemplates = async (): Promise<OriginalResponse | Success> =>
	restCall(GET, '/admin/administration/email-templates');

export const postChangeEmailTemplateStatus = async (payload: number): Promise<OriginalResponse | Success> =>
	restCall(POST, `/admin/administration/email-templates/${payload}/changeStatus`, payload);

export const postTestEmailTemplate = async (payload: any): Promise<OriginalResponse | Success> =>
	restCall(POST, `/admin/administration/email-templates/test`, payload);

export const postNewEmailTemplate = async (payload: INewEmailTemplate): Promise<OriginalResponse | Success> =>
	restCall(POST, '/admin/administration/email-templates', payload);

export const fetchFileTemplates = async (): Promise<OriginalResponse | Success> =>
	restCall(GET, '/admin/administration/file-templates');

export const postChangeFileTemplateStatus = async (payload: number | null): Promise<OriginalResponse | Success> =>
	restCall(POST, `/admin/administration/file-templates/${payload}/changeStatus`, payload);

export const fetchDownloadFileTemplate = async (payload: number): Promise<OriginalResponse | Success> =>
	restCall(EXPORTFILE, `/admin/administration/file-templates/${payload}/download`, payload);

export const postUploadFileTemplate = async (
	payload: Document | FormData | null
): Promise<OriginalResponse | Success> => restCall(POST, '/admin/administration/file-templates/upload', payload);

export const fetchCachesList = async (): Promise<OriginalResponse | Success> =>
	restCall(GET, '/admin/administration/cache');

export const deleteSingleCache = async (payload: number): Promise<OriginalResponse | Success> =>
	restCall(DELETE, `/admin/administration/cache/${payload}/delete`, payload);

export const deleteSingleCacheByName = async (payload: string): Promise<OriginalResponse | Success> =>
	restCall(DELETE, `/admin/administration/cache/item/${payload}/delete`, payload);

export const deleteAllCaches = async (payload: []): Promise<OriginalResponse | Success> =>
	restCall(DELETE, '/admin/administration/cache/deleteAll', payload);

export const deleteAllUserCaches = async (): Promise<OriginalResponse | Success> =>
	restCall(POST, '/public/datasync/users/refresh', {});

export const deleteAllSingleUserCaches = async (username: string): Promise<OriginalResponse | Success> =>
	restCall(POST, `/admin/administration/cache/${username}/deleteAll`, {});

export const deleteAllCompaniesCaches = async (): Promise<OriginalResponse | Success> =>
	restCall(POST, '/public/datasync/companies/refresh', {});

export const fetchAccessControlList = async (): Promise<OriginalResponse | Success> =>
	restCall(GET, '/admin/accessControl/list');

export const fetchAccessControlListAddUser = async (): Promise<OriginalResponse | Success> =>
	restCall(GET, '/admin/accessControl/add');

export const fetchAccessControlEditUser = async (payload: number): Promise<OriginalResponse | Success> =>
	restCall(GET, `/admin/accessControl/edit/${payload}`);

export const removeAllAccesses = async (payload: number): Promise<OriginalResponse | Success> =>
	restCall(DELETE, `/admin/accessControl/delete/${payload}`, payload);

export const putAccessControl = async (
	payload: IGrantAccessControl,
	userId: number
): Promise<OriginalResponse | Success> => restCall(PUT, `/admin/accessControl/edit/${userId}`, payload);

export const authorizationCodeRenewal = async (payload: string): Promise<OriginalResponse | Success> =>
	restCall(POST, `/admin/digitalSign/renewAuthorizationCode?code=${payload}`);

export const fetchReleasesList = async (projectKey: string): Promise<OriginalResponse | Success> =>
	restCall(GET, `/admin/releases/project/${projectKey}`);

export const fetchReleaseNotesList = async (id: string): Promise<OriginalResponse | Success> =>
	restCall(GET, `/admin/releases/list/${id}`);

export const fetchReleaseNotesEmail = async (
	id: string,
	name: string,
	projName: string
): Promise<OriginalResponse | Success> => restCall(GET, `/admin/getEmail/releaseNotes/${id}/${name}/${projName}`);

export const sendReleaseNotesEmail = async (payload: IReleaseNotesEmail): Promise<OriginalResponse | Success> =>
	restCall(POST, `/admin/sendEmail/releaseEmail`, payload);

export const resetAllNewReleasePopups = async (): Promise<OriginalResponse | Success> =>
	restCall(GET, `/admin/releases/getResetAllNewReleasePopups`);

export const getJobExecutionsList = async (): Promise<OriginalResponse | Success> =>
	restCall(GET, `/admin/jobs/executions/list`);

export const fetchDeleteJobExecution = async (id: number): Promise<OriginalResponse | Success> =>
	restCall(GET, `/admin/jobs/executions/delete/${id}`);

export const fetchDeleteAllUnfinishedJobExecutions = async (): Promise<OriginalResponse | Success> =>
	restCall(GET, `/admin/jobs/executions/delete/all/unfinished`);

export const fetchJobExecution = async (jobId: string, withError: boolean): Promise<OriginalResponse | Success> =>
	restCall(GET, `/admin/jobs/executions/execute/${jobId}/${withError}`);

export const fetchAllJobs = async (): Promise<OriginalResponse | Success> =>
	restCall(GET, `/admin/jobs/executions/jobs/list`);

export const fetchRolesList = async (): Promise<OriginalResponse | Success> => restCall(GET, `/admin/roles/list`);

export const getAppLinksList = async (): Promise<OriginalResponse | Success> => restCall(GET, `/admin/app/links/list`);

export const deleteApp = async (id): Promise<OriginalResponse | Success> =>
	restCall(GET, `/admin/app/links/delete/${id}`);

export const postAppLink = async (payload: FormData): Promise<OriginalResponse> =>
	restCall(POST, `/admin/app/links/edit`, payload, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	}) as unknown as OriginalResponse;

export const postAppLinkNoImage = async (payload: FormData): Promise<OriginalResponse> =>
	restCall(POST, `/admin/app/links/edit/noImage`, payload) as unknown as OriginalResponse;

export const postAddAppLink = async (payload: FormData): Promise<OriginalResponse> =>
	restCall(POST, `/admin/app/links/create`, payload, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	}) as unknown as OriginalResponse;

export const fetchAllGuides = async (): Promise<OriginalResponse | Success> =>
	restCall(GET, '/admin/administration/guides');

export const postUploadGuides = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(POST, '/admin/administration/guides/upload', payload);

export const fetchUserDeactivatedGuides = async (username: String): Promise<OriginalResponse | Success> =>
	restCall(GET, `/admin/administration/guides/deactivated/${username}`);

export const postMarkAsNotRead = async (username: String, guideName: String): Promise<OriginalResponse | Success> =>
	restCall(PUT, `/admin/administration/guides/${username}/${guideName}/markAsNotRead`);
