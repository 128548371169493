import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { sendDeleteUserFromDB, clearUsersError } from 'store/administration/users/thunks';
import {
	getUserDeactivatedGuides,
	markAsNotRead,
	sendDeleteAllSingleUserCaches
} from 'store/administration/administration/thunks';
import { sendDeleteAllUserNotifications } from 'store/notifications/thunks';
import { getResignationList } from 'store/administration/admissions/thunks';
import { setUsersPageFilter } from 'store/filters/actions';
import { AppState } from 'typings/state';

import Users from './Users';
const mapStateToProps = ({ users, administration, filters, admissions }: AppState) => ({
	users,
	administration,
	filters,
	admissions
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getResignationList,
			clearUsersError,
			setUsersPageFilter,
			sendDeleteAllUserNotifications,
			sendDeleteAllSingleUserCaches,
			sendDeleteUserFromDB,
			getUserDeactivatedGuides,
			markAsNotRead
		},
		dispatch
	);

export type UsersProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Users);
