import { createReducer } from 'typesafe-actions';
import { AdministrationActions as Actions, AdministrationState } from 'typings/store/admin/administration';

const INITIAL_STATE: AdministrationState = {
	loading: false,
	error: null,
	listEmailTemplates: null,
	changeEmailTemplateStatus: null,
	newEmailTemplate: null,
	listFileTemplates: null,
	changeFileTemplateStatus: null,
	downloadFileTemplate: null,
	uploadFileTemplate: null,
	listCaches: null,
	deleteSingleCache: null,
	deleteAllCaches: null,
	accessControlList: null,
	removeAllAccesses: null,
	accessControlListAddUser: null,
	accessControlEditUser: null,
	accessControlPut: null,
	codeRenewalActions: null,
	listReleases: null,
	listReleaseNotes: null,
	releaseNotesEmail: null,
	releaseNotesEmailAction: null,
	jobExecutionsData: null,
	jobsList: null,
	rolesListData: null,
	appLinksData: null,
	guidesData: null,
	deactivatedGuides: null
};

const AdministrationReducer = createReducer(INITIAL_STATE, {
	[Actions.SET_LOADING]: state => ({ ...state, loading: true }),
	[Actions.SET_LOADING_FALSE]: state => ({ ...state, loading: false }),
	[Actions.SET_ERROR]: (state, { payload }) => ({ ...state, error: payload }),
	[Actions.GET_EMAIL_TEMPLATES]: (state, { payload }) => ({
		...state,
		loading: false,
		listEmailTemplates: payload
	}),
	[Actions.POST_CHANGE_EMAIL_TEMPLATE_STATUS]: (state, { payload }) => ({
		...state,
		loading: false,
		changeEmailTemplateStatus: payload.payload,
		listEmailTemplates: payload.newData
	}),
	[Actions.POST_NEW_EMAIL_TEMPLATE]: (state, { payload }) => ({
		...state,
		loading: false,
		newEmailTemplate: payload.payload,
		listEmailTemplates: payload.newData
	}),
	[Actions.GET_FILE_TEMPLATES]: (state, { payload }) => ({
		...state,
		loading: false,
		listFileTemplates: payload
	}),
	[Actions.POST_CHANGE_FILE_TEMPLATE_STATUS]: (state, { payload }) => ({
		...state,
		loading: false,
		changeFileTemplateStatus: payload.payload,
		listFileTemplates: payload.newData
	}),
	[Actions.GET_DOWNLOAD_FILE_TEMPLATE]: (state, { payload }) => ({
		...state,
		loading: false,
		downloadFileTemplate: payload
	}),
	[Actions.POST_UPLOAD_FILE_TEMPLATE]: (state, { payload }) => ({
		...state,
		loading: false,
		uploadFileTemplate: payload.payload,
		listFileTemplates: payload.newData
	}),
	[Actions.GET_CACHES_LIST]: (state, { payload }) => ({
		...state,
		loading: false,
		listCaches: payload
	}),
	[Actions.DELETE_SINGLE_CACHE]: (state, { payload }) => ({
		...state,
		loading: false,
		deleteSingleCache: payload.payload,
		listCaches: payload.newData
	}),
	[Actions.DELETE_ALL_CACHES]: (state, { payload }) => ({
		...state,
		loading: false,
		deleteAllCaches: payload.payload,
		listCaches: payload.newData
	}),
	[Actions.DELETE_ALL_USER_CACHES]: (state, { payload }) => ({
		...state,
		loading: false,
		deleteAllCaches: payload.payload,
		listCaches: payload.newData
	}),
	[Actions.DELETE_ALL_COMPANY_CACHES]: (state, { payload }) => ({
		...state,
		loading: false,
		deleteAllCaches: payload.payload,
		listCaches: payload.newData
	}),
	[Actions.GET_ACCESS_CONTROL_LIST]: (state, { payload }) => ({
		...state,
		loading: false,
		accessControlList: payload
	}),
	[Actions.REMOVE_ALL_ACCESSES]: (state, { payload }) => ({
		...state,
		loading: false,
		removeAllAccesses: payload.payload,
		accessControlList: payload.newData,
		accessControlListAddUser: payload.newData2
	}),

	[Actions.GET_ACCESS_CONTROL_LIST_ADD_USER]: (state, { payload }) => ({
		...state,
		loading: false,
		accessControlListAddUser: payload
	}),

	[Actions.GET_ACCESS_CONTROL_EDIT_USER]: (state, { payload }) => ({
		...state,
		loading: false,
		accessControlEditUser: payload
	}),
	[Actions.PUT_ACCESS_CONTROL]: (state, { payload }) => ({
		...state,
		loading: false,
		accessControlPut: payload.payload,
		accessControlListAddUser: payload.newData,
		accessControlList: payload.newData2
	}),
	[Actions.CLEAR_EDIT_USER]: state => ({ ...state, loading: false, accessControlEditUser: null }),
	[Actions.SET_AUTHORIZATION_CODE_RENEWAL]: (state, { payload }) => ({
		...state,
		...INITIAL_STATE,
		codeRenewalActions: payload
	}),
	//CONTAINS ALL RELEASES DONE SO FAR (EX: 3.5, 3.4 ...)
	[Actions.GET_ALL_RELEASES_LIST]: (state, { payload }) => ({
		...state,
		loading: false,
		listReleases: payload
	}),
	//CONTAINS THE CONTENT OF ONE RELEASE NOTE
	[Actions.GET_RELEASE_NOTE_LIST]: (state, { payload }) => ({
		...state,
		loading: false,
		listReleaseNotes: payload
	}),
	[Actions.GET_RELEASENOTES_EMAIL]: (state, { payload }) => ({
		...state,
		loading: false,
		releaseNotesEmail: payload
	}),
	[Actions.CLEAR_RELEASENOTES_EMAIL]: state => ({ ...state, loading: false, releaseNotesEmail: null }),
	[Actions.SEND_RELEASENOTES_EMAIL]: (state, { payload }) => ({
		...state,
		loading: false,
		releaseNotesEmailAction: payload
	}),
	[Actions.GET_JOB_EXECUTIONS_LIST]: (state, { payload }) => ({
		...state,
		loading: false,
		jobExecutionsData: payload
	}),
	[Actions.DELETE_JOB_EXECUTION]: (state, { payload }) => ({
		...state,
		loading: false,
		jobExecutionsData: payload
	}),
	[Actions.GET_ROLES_LIST]: (state, { payload }) => ({
		...state,
		loading: false,
		rolesListData: payload
	}),
	[Actions.GET_APP_LINKS_LIST]: (state, { payload }) => ({
		...state,
		loading: false,
		appLinksData: payload
	}),
	[Actions.GET_JOBS_LIST]: (state, { payload }) => ({
		...state,
		loading: false,
		jobsList: payload
	}),
	[Actions.GET_APP_GUIDES_LIST]: (state, { payload }) => ({
		...state,
		loading: false,
		guidesData: payload
	}),
	[Actions.DEACTIVATED_GUIDES]: (state, { payload }) => ({
		...state,
		loading: false,
		deactivatedGuides: payload
	})
});

export default AdministrationReducer;
