//* EXTERNAL LIBS
import React, { useEffect, FC, useMemo, useState } from 'react';

//* EXTERNAL LIBS --> MUI
import { Button, Grid, Typography } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, CollapseTable, TabHeader, Tab, Dialog, Table } from 'xpand-ui/core';
import { Checkbox } from 'xpand-ui/forms';

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import { useStyles } from './styles';
import { TimesheetProps } from '.';
import { decimalToHours } from '../../../PersonalInfo/MyTimeTracking/MyTimesheets/utils';

import {
	getApprovalsTimesheet,
	getApprovalsTimesheetPeriodDetails,
	postApprovalsTimesheetWithoutTimeOffApprove,
	postApprovalsTimesheetWithoutTimeOffReject,
	postApprovalsTimesheetWithoutTimeOffRejectWithTimeOff,
	postApprovalsTimesheetWithoutTimeOffApproveWithTimeOff
} from 'store/approvals/thunks';
import { convertMinutesToTime, deepEqual, formatDate, formatDateFinal, tabsTimesheet } from '../utils';
import { IUserToken } from 'typings/store/generalTypes';
import { ITableColumn } from 'typings/store/ComponentLib';
import { format } from 'date-fns';
import Filters from '../Components/Filters/Filters';
import { setRemoveUpdateTimesheet } from 'store/approvals/actions';
import { addNotification } from 'lib/utils/notifications';
import { useDispatch, useSelector } from 'react-redux';

//* COMPONENT INTERFACES
interface ITimesheet extends TimesheetProps {
	isAdmin: string;
	goToPage: (path: string) => void;
	loggedUser: IUserToken;
}

//* COMPONENT
const Timesheet: FC<ITimesheet> = ({ loggedUser }) => {
	const classes = useStyles();
	const approvalsTimesheet: any = useSelector(state => state.approvals.approvalsTimesheet);
	const approvalsTimesheetDetails: any = useSelector(state => state.approvals.approvalsTimesheetPeriodDetails);
	const updateTimesheet: any = useSelector(state => state.approvals.updateTimesheet);
	const loading = useSelector(state => state.approvals.loading);
	const [approvalsTimesheetFinal, setApprovalsTimesheetFinal] = useState<any>();
	const [firstLoading, setFirstLoading] = useState<any>(true);
	const [auxApprovalsTimesheet, setAuxApprovalsTimesheet] = useState<any>();
	//Approval Details
	const [openDetailsModal, setOpenDetailsModal] = useState(false);
	const [entryIdToDetails, setEntryIdToDetails] = useState<any>();
	const [timeEntryWeekTableData, setTimeEntryWeekTableData] = useState<any>();
	const [commentsListTableData, setCommentsListTableData] = useState<any>();
	const [showAll, setShowAll] = useState(false);
	const [otherInfoDetails, setOtherInfoDetails] = useState<any>(null);

	const dispatch = useDispatch();
	const [tabSelected, setTabSelected] = useState(0);
	const [tabsTimesheetFinal, setTabsTimesheetFinal] = useState(tabsTimesheet);
	//Approvals -My Projects Time Entry Approvals
	const [groupedDataApprovalEntriesForProjectManager, setGroupedDataApprovalEntriesForProjectManager] =
		useState<any>();
	//Approvals - My Team Time Entry Approvals
	const [groupedDataApprovalEntriesForTeamLeadSummarized, setGroupedDataApprovalEntriesForTeamLeadSummarized] =
		useState<any>();
	//Approvals - Employee Manager Time Entry Approvals
	const [
		groupedDataApprovalEntriesForEmployeeManagerSummarized,
		setGroupedDataApprovalEntriesForEmployeeManagerSummarized
	] = useState<any>();
	//Approvals - Project Manager Time Off Approvals
	const [groupedDataAccountProjectsSpecificEmployeeTimeOff, setGroupedDataAccountProjectsSpecificEmployeeTimeOff] =
		useState<any>();
	//Approvals - Specific External User Time Entry Approvals
	const [
		groupedDataApprovalEntriesForSpecificExternalUserSummarized,
		setGroupedDataApprovalEntriesForSpecificExternalUserSummarized
	] = useState<any>();
	//Approvals - Specific Employee Time Entry Approvals
	const [
		groupedDataApprovalEntriesForSpecificEmployeeSummarized,
		setGroupedDataApprovalEntriesForSpecificEmployeeSummarized
	] = useState<any>();

	//Approvals -Specific Employee Time Off Approvals
	const [
		groupedDataApprovalEntriesSpecificEmployeeForTimeOff,
		setGroupedDataApprovalEntriesSpecificEmployeeForTimeOff
	] = useState<any>();

	const rowsHeaderApprovalEntriesForProjectManager = useMemo(
		() => ['Period', 'Billable Hours', ' Non-Billable Hours', 'Total Hours'],
		[]
	);
	const rowsHeaderApprovalEntriesForProjectManagerTimeOff = useMemo(
		() => ['Date', 'Project Name', 'Time Off Type', 'Total Hours', 'Description'],
		[]
	);

	//! ------------------------ TIMESHEET APPROVALS --------------------------------------//
	//Gets all timesheet approvals of the logged user
	useEffect(() => {
		dispatch(
			getApprovalsTimesheet({
				isFirstLoad: false,
				username: 'arfp',
				includeDateRange: false
			})
		);
	}, []);

	//Main data is copied to a variable to be manipulated
	useEffect(() => {
		setFirstLoading(true);
		setAuxApprovalsTimesheet(approvalsTimesheet);
	}, [approvalsTimesheet]);

	//When the first loading is completed or a new loading after update is completed reset the final variable **Final variable has all the changed information (status, comments) **
	useEffect(() => {
		if (firstLoading && approvalsTimesheet) {
			setApprovalsTimesheetFinal(auxApprovalsTimesheet);
			setFirstLoading(false);
		}
		if (updateTimesheet && !firstLoading && auxApprovalsTimesheet) {
			setApprovalsTimesheetFinal(auxApprovalsTimesheet);
			setFirstLoading(false);
			dispatch(setRemoveUpdateTimesheet());
		}
	}, [auxApprovalsTimesheet]);

	//Used to update the final variable with the changes that the user did (status, commentss)
	useEffect(() => {
		//Check if the aux variable is equal to the final variable if not updates with the changes that the user did
		if (!deepEqual(auxApprovalsTimesheet, approvalsTimesheetFinal) && auxApprovalsTimesheet) {
			setAuxApprovalsTimesheet(
				Object.fromEntries(
					Object.entries(auxApprovalsTimesheet).map(([key, array]) => {
						// Check if the value is an array
						if (Array.isArray(array)) {
							// Return the updated array after filtering and mapping
							return [
								key,
								array.map(item => {
									//Find the updated entry
									const updatedEntry = approvalsTimesheetFinal[key].find(
										entry =>
											entry.accountEmployeeTimeEntryPeriodId ===
											item.accountEmployeeTimeEntryPeriodId
									);

									// If an updated entry is found and there are changes, update the original entry
									if (updatedEntry) {
										// Compare and update only the fields that have changed
										const hasChanges = Object.keys(updatedEntry).some(
											key2 => updatedEntry[key2] !== item[key2]
										);

										if (hasChanges) {
											// Merge the updated entry into the original entry (only changed fields)
											return { ...item, ...updatedEntry };
										}
									}

									// If no updates are found, return the original entry unchanged
									return item;
								})
							];
						}
						// If the value is not an array, return it unchanged (or handle it as needed)
						return [key, array];
					})
				)
			);
		}
	}, [approvalsTimesheetFinal]);

	//Fill every table with the respective information
	useEffect(() => {
		//Approvals - My Projects Time Entry Approvals
		if (approvalsTimesheetFinal?.approvalEntriesForProjectManagerSummarized) {
			setGroupedDataApprovalEntriesForProjectManager(
				Object.values(
					approvalsTimesheetFinal?.approvalEntriesForProjectManagerSummarized?.reduce((acc, item) => {
						if (!acc[item.employeeName]) {
							acc[item.employeeName] = [];
						}

						const period =
							format(new Date(item.timeEntryStartDate), 'dd/MM/yyyy') +
							' - ' +
							format(new Date(item.timeEntryEndDate), 'dd/MM/yyyy');
						acc[item.employeeName].push({
							key: 'approvalEntriesForProjectManagerSummarized',
							employeeName: item.employeeName,
							id: item.accountEmployeeTimeEntryPeriodId,
							period: period,
							status: item.status,
							comment: item.comment || null,
							billableTotalMinutes: convertMinutesToTime(item.billableTotalMinutes),
							nonBillableTotalMinutes: convertMinutesToTime(item.nonBillableTotalMinutes),
							totalMinutes: convertMinutesToTime(item.totalMinutes)
						});
						return acc;
					}, {})
				)
			);
		}
		//Approvals - My Team Time Entry Approvals
		if (approvalsTimesheetFinal?.approvalEntriesForTeamLeadSummarized) {
			setGroupedDataApprovalEntriesForTeamLeadSummarized(
				Object.values(
					approvalsTimesheetFinal?.approvalEntriesForTeamLeadSummarized?.reduce((acc, item) => {
						if (!acc[item.employeeName]) {
							acc[item.employeeName] = [];
						}

						const period =
							format(new Date(item.timeEntryStartDate), 'dd/MM/yyyy') +
							' - ' +
							format(new Date(item.timeEntryEndDate), 'dd/MM/yyyy');
						acc[item.employeeName].push({
							key: 'approvalEntriesForTeamLeadSummarized',
							employeeName: item.employeeName,
							id: item.accountEmployeeTimeEntryPeriodId,
							period: period,
							status: item.status,
							comment: item.comment || null,
							billableTotalMinutes: convertMinutesToTime(item.billableTotalMinutes),
							nonBillableTotalMinutes: convertMinutesToTime(item.nonBillableTotalMinutes),
							totalMinutes: convertMinutesToTime(item.totalMinutes)
						});
						return acc;
					}, {})
				)
			);
		}
		//Approvals - Employee Manager Time Entry Approvals
		if (approvalsTimesheetFinal?.approvalEntriesForEmployeeManagerSummarized) {
			setGroupedDataApprovalEntriesForEmployeeManagerSummarized(
				Object.values(
					approvalsTimesheetFinal?.approvalEntriesForEmployeeManagerSummarized?.reduce((acc, item) => {
						if (!acc[item.employeeName]) {
							acc[item.employeeName] = [];
						}

						const period =
							format(new Date(item.timeEntryStartDate), 'dd/MM/yyyy') +
							' - ' +
							format(new Date(item.timeEntryEndDate), 'dd/MM/yyyy');
						acc[item.employeeName].push({
							key: 'approvalEntriesForEmployeeManagerSummarized',
							employeeName: item.employeeName,
							id: item.accountEmployeeTimeEntryPeriodId,
							period: period,
							status: item.status,
							comment: item.comment || null,
							billableTotalMinutes: convertMinutesToTime(item.billableTotalMinutes),
							nonBillableTotalMinutes: convertMinutesToTime(item.nonBillableTotalMinutes),
							totalMinutes: convertMinutesToTime(item.totalMinutes)
						});
						return acc;
					}, {})
				)
			);
		}
		//Approvals - Project Manager Time Off Approvals
		if (approvalsTimesheetFinal?.approvalEntriesForProjectManagerTimeOff) {
			setGroupedDataAccountProjectsSpecificEmployeeTimeOff(
				Object.values(
					approvalsTimesheetFinal?.approvalEntriesForProjectManagerTimeOff?.reduce((acc, item) => {
						if (!acc[item.employeeName]) {
							acc[item.employeeName] = [];
						}
						acc[item.employeeName].push({
							key: 'approvalEntriesForProjectManagerTimeOff',
							employeeName: item.employeeName,
							id: item.accountEmployeeTimeEntryPeriodId,
							status: item.status,
							date: item.timeEntryDate,
							projectName: 'XPAND IT - HUB - HR ABSENCE',
							timeoffType: item.accountTimeOffType,
							totalHours: convertMinutesToTime(item.totalMinutes),
							description: item.description
						});
						return acc;
					}, {})
				)
			);
		}
		//Approvals - Specific External User Time Entry Approvals
		if (approvalsTimesheetFinal?.approvalEntriesForSpecificExternalUserSummarized) {
			setGroupedDataApprovalEntriesForSpecificExternalUserSummarized(
				Object.values(
					approvalsTimesheetFinal?.approvalEntriesForSpecificExternalUserSummarized?.reduce((acc, item) => {
						if (!acc[item.employeeName]) {
							acc[item.employeeName] = [];
						}

						const period =
							format(new Date(item.timeEntryStartDate), 'dd/MM/yyyy') +
							' - ' +
							format(new Date(item.timeEntryEndDate), 'dd/MM/yyyy');
						acc[item.employeeName].push({
							key: 'approvalEntriesForSpecificExternalUserSummarized',
							employeeName: item.employeeName,
							id: item.accountEmployeeTimeEntryPeriodId,
							period: period,
							status: item.status,
							comment: item.comment || null,
							billableTotalMinutes: convertMinutesToTime(item.billableTotalMinutes),
							nonBillableTotalMinutes: convertMinutesToTime(item.nonBillableTotalMinutes),
							totalMinutes: convertMinutesToTime(item.totalMinutes)
						});
						return acc;
					}, {})
				)
			);
		}
		//Approvals - Specific Employee Time Entry Approvals
		if (approvalsTimesheetFinal?.approvalEntriesForSpecificEmployeeSummarized) {
			setGroupedDataApprovalEntriesForSpecificEmployeeSummarized(
				Object.values(
					approvalsTimesheetFinal?.approvalEntriesForSpecificEmployeeSummarized?.reduce((acc, item) => {
						if (!acc[item.employeeName]) {
							acc[item.employeeName] = [];
						}

						const period =
							format(new Date(item.timeEntryStartDate), 'dd/MM/yyyy') +
							' - ' +
							format(new Date(item.timeEntryEndDate), 'dd/MM/yyyy');
						acc[item.employeeName].push({
							key: 'approvalEntriesForSpecificEmployeeSummarized',
							employeeName: item.employeeName,
							id: item.accountEmployeeTimeEntryPeriodId,
							period: period,
							status: item.status,
							comment: item.comment || null,
							billableTotalMinutes: convertMinutesToTime(item.billableTotalMinutes),
							nonBillableTotalMinutes: convertMinutesToTime(item.nonBillableTotalMinutes),
							totalMinutes: convertMinutesToTime(item.totalMinutes)
						});
						return acc;
					}, {})
				)
			);
		}
		//Approvals -Specific Employee Time Off Approvals
		if (approvalsTimesheetFinal?.approvalEntriesSpecificEmployeeForTimeOff) {
			setGroupedDataApprovalEntriesSpecificEmployeeForTimeOff(
				Object.values(
					approvalsTimesheetFinal?.approvalEntriesSpecificEmployeeForTimeOff?.reduce((acc, item) => {
						if (!acc[item.employeeName]) {
							acc[item.employeeName] = [];
						}

						const period =
							format(new Date(item.timeEntryStartDate), 'dd/MM/yyyy') +
							' - ' +
							format(new Date(item.timeEntryEndDate), 'dd/MM/yyyy');
						acc[item.employeeName].push({
							key: 'approvalEntriesSpecificEmployeeForTimeOff',
							employeeName: item.employeeName,
							id: item.accountEmployeeTimeEntryPeriodId,
							period: period,
							status: item.status,
							comment: item.comment || null,
							billableTotalMinutes: convertMinutesToTime(item.billableTotalMinutes),
							nonBillableTotalMinutes: convertMinutesToTime(item.nonBillableTotalMinutes),
							totalMinutes: convertMinutesToTime(item.totalMinutes)
						});
						return acc;
					}, {})
				)
			);
		}
	}, [approvalsTimesheetFinal]);

	//Removes unecessary tabs that the user don't have nothing to approve
	useEffect(() => {
		if (approvalsTimesheetFinal) {
			let auxTabsTimesheet = tabsTimesheet;
			//Approvals -My Projects Time Entry Approvals
			if (approvalsTimesheetFinal?.approvalEntriesForProjectManagerSummarized.length == 0) {
				auxTabsTimesheet = auxTabsTimesheet.filter(item => item.id !== 'myProjectsTimeEntryApprovals');
			}
			//Approvals - My Team Time Entry Approvals
			if (approvalsTimesheetFinal?.approvalEntriesForTeamLeadSummarized.length == 0) {
				auxTabsTimesheet = auxTabsTimesheet.filter(item => item.id !== 'myTeamTimeEntryApprovals');
			}
			//Approvals - Employee Manager Time Entry Approvals
			if (approvalsTimesheetFinal?.approvalEntriesForEmployeeManagerSummarized.length == 0) {
				auxTabsTimesheet = auxTabsTimesheet.filter(item => item.id !== 'employeeManagerTimeEntryApprovals');
			}
			//Approvals - Project Manager Time Off Approvals
			if (approvalsTimesheetFinal?.approvalEntriesForProjectManagerTimeOff.length == 0) {
				auxTabsTimesheet = auxTabsTimesheet.filter(item => item.id !== 'projectManagerTimeOffApprovals');
			}
			//Approvals - Specific External User Time Entry Approvals
			if (approvalsTimesheetFinal?.approvalEntriesForSpecificExternalUserSummarized.length == 0) {
				auxTabsTimesheet = auxTabsTimesheet.filter(
					item => item.id !== 'specificExternalUserTimeEntryApprovals'
				);
			}
			//Approvals - Specific Employee Time Entry Approvals
			if (approvalsTimesheetFinal?.approvalEntriesForSpecificEmployeeSummarized.length == 0) {
				auxTabsTimesheet = auxTabsTimesheet.filter(item => item.id !== 'specificEmployeeTimeEntryApprovals');
			}
			//Approvals -Specific Employee Time Off Approvals
			if (approvalsTimesheetFinal?.approvalEntriesSpecificEmployeeForTimeOff.length == 0) {
				auxTabsTimesheet = auxTabsTimesheet.filter(item => item.id !== 'specificEmployeeTimeOffApprovals');
			}

			if (tabSelected > auxTabsTimesheet?.length - 1) {
				setTabSelected(0);
			}

			setTabsTimesheetFinal(auxTabsTimesheet);
		}
	}, [approvalsTimesheetFinal]);

	//!------------------------------------------UPDATE APPROVALS-------------------------------------------//

	//Whenever an update is finish refresh tables with new data
	useEffect(() => {
		if (updateTimesheet) {
			dispatch(
				getApprovalsTimesheet({
					isFirstLoad: false,
					username: loggedUser.username,
					includeDateRange: false
				})
			);
		}
	}, [updateTimesheet]);

	//Method responsible to create the payload and send it to API
	const updateTimeEntryApprovals = () => {
		let entriesToApprove: any = [];
		let entriesToReject: any = [];
		let entriesToApproveWithTimeOff: any = [];
		let entriesToRejectWithTimeOff: any = [];

		//Checks if there is an entry with rejected status and with no comment, if so sends notification
		for (let entries in auxApprovalsTimesheet) {
			for (let i = 0; i < auxApprovalsTimesheet[entries].length; i++) {
				const e = auxApprovalsTimesheet[entries][i];
				if (e.status === 'rejected' && (e.comment === undefined || e.comment === '')) {
					addNotification(
						'danger',
						'One or more entries are rejected without comment. Please insert a comment in all rejected entries.'
					);
					// Stop further execution by returning early
					return;
				}
			}
		}

		// Creates the payload to send to API deppendig on the status
		for (let entries in auxApprovalsTimesheet) {
			auxApprovalsTimesheet[entries].map(e => {
				if (e.status === 'approved' && !e.isTimeOff) {
					entriesToApprove.push({
						username: loggedUser.username,
						timeEntryAccountEmployeeId: e.timeEntryAccountEmployeeId,
						startDate: e.timeEntryStartDate,
						endDate: e.timeEntryEndDate,
						comments: e.comment ? e.comment : '',
						chkTeamLead: entries === 'approvalEntriesForTeamLeadSummarized' ? true : false,
						chkProjectManager: entries === 'approvalEntriesForProjectManagerSummarized' ? true : false,
						chkSpecificEmployee: entries === 'approvalEntriesForSpecificEmployeeSummarized' ? true : false,
						chkSpecificExternalUser:
							entries === 'approvalEntriesForSpecificExternalUserSummarized' ? true : false,
						chkEmployeeManager: entries === 'approvalEntriesForEmployeeManagerSummarized' ? true : false,
						accountEmployeeTimeEntryPeriodId: e.accountEmployeeTimeEntryPeriodId,
						employeeName: e.employeeName,
						totalMinutes: e.totalMinutes,
						emailAddress: e.emailAddress,
						timeEntryViewType: e.timeEntryViewType
					});
				} else if (e.status === 'approved' && e.isTimeOff) {
					entriesToApproveWithTimeOff.push({
						accountEmployeeTimeEntryId: e.accountEmployeeTimeEntryId,
						timeSheetApprovalTypeId: e.accountApprovalTypeId,
						timeSheetApprovalPathId: e.accountApprovalPathId,
						approvedByEmployeeId: loggedUser.username,
						comments: e.comment ? e.comment : '',
						isReject: false,
						isApproved: true,
						accountProjectId: 0,
						batchId: null,
						accountEmployeeId: e.timeEntryAccountEmployeeId,
						accountTimeOffTypeId: e.accountTimeOffTypeId,
						totalTime: convertMinutesToTime(e.totalMinutes),
						policyExecutionType: loggedUser.username + ' - ' + loggedUser.name,
						policyEarnResetAutidAction: 'Approved',
						auditSource: 'Time Sheet Approval',
						systemTimeEntryHoursFormat: 'Time',
						accountEmployeeTimeEntryPeriodId: e.accountEmployeeTimeEntryPeriodId
						//,"employeeManagerId": 575
					});
				}
				if (e.status === 'rejected' && !e.isTimeOff) {
					entriesToReject.push({
						username: loggedUser.username,
						timeEntryAccountEmployeeId: e.timeEntryAccountEmployeeId,
						startDate: e.timeEntryStartDate,
						endDate: e.timeEntryEndDate,
						comments: e.comment ? e.comment : '',
						chkTeamLead: entries === 'approvalEntriesForTeamLeadSummarized' ? true : false,
						chkProjectManager: entries === 'approvalEntriesForProjectManagerSummarized' ? true : false,
						chkSpecificEmployee: entries === 'approvalEntriesForSpecificEmployeeSummarized' ? true : false,
						chkSpecificExternalUser:
							entries === 'approvalEntriesForSpecificExternalUserSummarized' ? true : false,
						chkEmployeeManager: entries === 'approvalEntriesForEmployeeManagerSummarized' ? true : false,
						accountEmployeeTimeEntryPeriodId: e.accountEmployeeTimeEntryPeriodId,
						employeeName: e.employeeName,
						totalMinutes: e.totalMinutes,
						emailAddress: e.emailAddress,
						timeEntryViewType: e.timeEntryViewType,
						accountProjectId: e.accountProjectId
					});
				} else if (e.status === 'rejected' && e.isTimeOff) {
					entriesToRejectWithTimeOff.push({
						accountEmployeeTimeEntryId: e.accountEmployeeTimeEntryId,
						timeSheetApprovalTypeId: e.accountApprovalTypeId,
						timeSheetApprovalPathId: e.accountApprovalPathId,
						rejectedByEmployeeId: loggedUser.username,
						comments: e.comment ? e.comment : '',
						isReject: true,
						isApproved: false,
						accountProjectId: 0,
						batchId: null
						//,"employeeManagerId": 575
					});
				}
			});
		}

		//Send payload to API depending on the length of each array
		if (entriesToApprove.length > 0) {
			dispatch(postApprovalsTimesheetWithoutTimeOffApprove(entriesToApprove));
		}
		if (entriesToReject.length > 0) {
			dispatch(postApprovalsTimesheetWithoutTimeOffReject(entriesToReject));
		}
		if (entriesToApproveWithTimeOff.length > 0) {
			dispatch(postApprovalsTimesheetWithoutTimeOffApproveWithTimeOff(entriesToApproveWithTimeOff));
		}
		if (entriesToRejectWithTimeOff.length > 0) {
			dispatch(postApprovalsTimesheetWithoutTimeOffRejectWithTimeOff(entriesToRejectWithTimeOff));
		}
	};

	//Disable Update button if there is no status selected in the entries
	const disableUpdateButton = () => {
		for (let entries in auxApprovalsTimesheet) {
			for (let e of auxApprovalsTimesheet[entries]) {
				if ((e.status === 'approved' || e.status === 'rejected') && !e.isTimeOff) {
					return false;
				} else if ((e.status === 'approved' || e.status === 'rejected') && e.isTimeOff) {
					return false;
				}
			}
		}
		return true;
	};

	//Every entry in the table will be approved, giving the status approved to the entries of the main data
	const approveAllProjectTimeEntryApprovals = group => {
		const updatedData = Object.keys(approvalsTimesheetFinal).reduce((acc, key) => {
			const allEntries = approvalsTimesheetFinal[key].filter(
				entry => entry.employeeName === group[0].employeeName
			);
			const allApproved = allEntries.every(e => e.status == 'approved');
			// Map over the entries and update the status accordingly
			acc[key] = approvalsTimesheetFinal[key].map(entry => {
				const matchingItem = group.find(
					item => item.id === entry.accountEmployeeTimeEntryPeriodId && item.key === key
				);

				if (matchingItem) {
					// If all are approved, set the status to null
					if (allApproved) {
						return {
							...entry,
							status: null
						};
					}
					// If not all are approved, approve all (set status to 'approved')
					else {
						return {
							...entry,
							status: 'approved'
						};
					}
				}
				// If no match found, return the entry as is
				return entry;
			});

			return acc;
		}, {});

		// Update the state with the modified data
		setApprovalsTimesheetFinal(updatedData);
	};

	//Every entry in the table will be rejected, giving the status rejected to the entries of the main data
	const rejectAllProjectTimeEntryApprovals = group => {
		const updatedData = Object.keys(approvalsTimesheetFinal).reduce((acc, key) => {
			const allEntries = approvalsTimesheetFinal[key].filter(
				entry => entry.employeeName === group[0].employeeName
			);

			const allRejected = allEntries.every(e => e.status == 'rejected');
			// Map over the entries and update the status accordingly
			acc[key] = approvalsTimesheetFinal[key].map(entry => {
				const matchingItem = group.find(
					item => item.id === entry.accountEmployeeTimeEntryPeriodId && item.key === key
				);

				if (matchingItem) {
					// If all are rejected, set the status to null
					if (allRejected) {
						return {
							...entry,
							status: null
						};
					}
					// If not all are rejected, approve all (set status to 'rejected')
					else {
						return {
							...entry,
							status: 'rejected'
						};
					}
				}
				// If no match found, return the entry as is
				return entry;
			});

			return acc;
		}, {});

		// Update the state with the modified data
		setApprovalsTimesheetFinal(updatedData);
	};

	//Approve a single entry in the table giving a status approved to the entry
	const approveProjectTimeEntryApprovals = item => {
		// Iterate through all arrays
		const updatedData: any = Object.keys(approvalsTimesheetFinal).reduce((acc, key) => {
			// Map over the array to find and update the entry
			acc[key] = approvalsTimesheetFinal[key].map(entry =>
				entry.accountEmployeeTimeEntryPeriodId === item.id && item.key === key
					? { ...entry, status: entry.status && entry.status === 'approved' ? null : 'approved' }
					: entry
			);
			return acc;
		}, {});

		// Update state with new data
		setApprovalsTimesheetFinal(updatedData);
	};

	//Reject a single entry in the table giving a status rejected to the entry
	const rejectProjectTimeEntryApprovals = item => {
		// Iterate through all arrays
		const updatedData = Object.keys(approvalsTimesheetFinal).reduce((acc, key) => {
			// Map over the array to find and update the entry
			acc[key] = approvalsTimesheetFinal[key].map(entry =>
				entry.accountEmployeeTimeEntryPeriodId === item.id && item.key === key
					? { ...entry, status: entry.status && entry.status === 'rejected' ? null : 'rejected' }
					: entry
			);
			return acc;
		}, {});
		// Update state with new data
		setApprovalsTimesheetFinal(updatedData);
	};

	//Gives comment to the entry
	const commentEntryApproval = (item, comment) => {
		// Iterate through all arrays
		const updatedData = Object.keys(approvalsTimesheetFinal).reduce((acc, key) => {
			// Map over the array to find and update the entry
			acc[key] = approvalsTimesheetFinal[key].map(entry =>
				entry.accountEmployeeTimeEntryPeriodId === item.id && item.key === key
					? { ...entry, comment: comment }
					: entry
			);
			return acc;
		}, {});
		// Update state with new data
		setApprovalsTimesheetFinal(updatedData);
	};

	//! ------------------------ DETAILS APPROVALS --------------------------------------//
	//Gets all details of the respective approval of a user
	useEffect(() => {
		if (openDetailsModal) {
			Object.keys(approvalsTimesheetFinal!).reduce((acc, key) => {
				approvalsTimesheetFinal![key].map(entry => {
					// Find the item in the group that matches the entry
					const matchingItem = entryIdToDetails.timeoffType
						? entry.accountEmployeeTimeEntryPeriodId === entryIdToDetails.id && entry.isTimeOff !== null
						: entry.accountEmployeeTimeEntryPeriodId === entryIdToDetails.id && entry.isTimeOff == null;
					// If there's a match, calls api for details data and saves necessary info for details modal title
					if (matchingItem) {
						setOtherInfoDetails({
							employeeName: entry.employeeName,
							startDate: entry.timeEntryStartDate,
							endDate: entry.timeEntryEndDate
						});
						dispatch(
							getApprovalsTimesheetPeriodDetails({
								accountEmployeeId: entry!.timeEntryAccountEmployeeId,
								startDate: entry!.timeEntryStartDate,
								endDate: entry!.timeEntryEndDate
							})
						);
					}
				});
			}, {});
		}
	}, [openDetailsModal]);

	//Prepares data for table time entry week view
	const tableWeekViewPayload = useMemo(
		() => timeEntryWeekTableData && timeEntryWeekTableData.map(e => e),
		[timeEntryWeekTableData]
	);

	//Prepares data for table comments List
	const tableCommentsListPayload = useMemo(
		() => commentsListTableData && commentsListTableData.map(e => e),
		[commentsListTableData]
	);

	// Helper function to get day of the week from timeEntryDate
	const getDayOfWeek = (dateString: any) => {
		const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
		const date = new Date(dateString);
		return days[date.getDay()];
	};

	//Creates the arrays responsible to contain every info for the details tables
	useEffect(() => {
		if (approvalsTimesheetDetails && approvalsTimesheetFinal) {
			let auxForTableWeekView: any = [];
			let auxForCommentsListTable: any = [];
			const isEmpty = obj => Object.keys(obj).length === 0;
			const matchingEntry = Object.keys(approvalsTimesheetFinal!).reduce((acc, key) => {
				// Iterate over each entry within the current key
				const foundEntry = approvalsTimesheetFinal![key].find((entry: any) => {
					// Check if the entry's accountProjectId matches the desired project ID
					return entryIdToDetails.timeoffType
						? entry.accountEmployeeTimeEntryPeriodId === entryIdToDetails.id && entry.isTimeOff !== null
						: entry.accountEmployeeTimeEntryPeriodId === entryIdToDetails.id && !entry.isTimeOff;
				});
				// If a	n entry is found, return it
				if (foundEntry) {
					return foundEntry;
				}

				return acc; // Continue reducing if no match is found
			}, undefined); // Initial value for reduce is undefined

			let groupedByProject = approvalsTimesheetDetails.accountEmployeeIdAndDateRangeWithStatus.reduce(
				(acc, item) => {
					//If is the first project to organize creates it
					if (Object.entries(acc).length === 0) {
						acc[`${item.projectName + item.taskName + 'splitSort' + item.accountCostCenter}`] = [];
					}
					//Creates projects arrays based on cost center, project name and task name. if one of them is different, creates a different array
					for (let currentProjects in acc) {
						acc[currentProjects].map(e => {
							if (
								e.accountCostCenter !== item.accountCostCenter ||
								e.projectName !== item.projectName ||
								e.taskName !== item.taskName
							) {
								// If the project name does not exist in the accumulator, create a new array for it
								if (
									!acc[`${item.projectName + item.taskName + 'splitSort' + item.accountCostCenter}`]
								) {
									acc[`${item.projectName + item.taskName + 'splitSort' + item.accountCostCenter}`] =
										[];
								}
							}
						});
					}

					// Push the current item into the array for the respective project name
					acc[`${item.projectName + item.taskName + 'splitSort' + item.accountCostCenter}`].push(item);

					return acc;
				},
				{}
			);

			// organize the object that has the entries organized by project
			groupedByProject = Object.keys(groupedByProject)
				.sort((a, b) => {
					const cleanA = a.split('splitSort')[0]; // Remove "Prefix: " if present
					const cleanB = b.split('splitSort')[0]; // Remove "Prefix: " if present
					console.log(cleanA, cleanB);
					return cleanA.localeCompare(cleanB); // Compare the cleaned names
				})
				.reduce((acc, key) => {
					acc[key] = groupedByProject[key];
					return acc;
				}, {});

			//Organize each arrya of project by date
			for (let project in groupedByProject) {
				groupedByProject[project].sort((a, b) => new Date(a.timeEntryDate) - new Date(b.timeEntryDate));
			}
			for (let project in groupedByProject) {
				let auxLine: any = {
					projectName: groupedByProject[project][0].projectName,
					taskName: groupedByProject[project][0].taskName,
					costCenter: groupedByProject[project][0].accountCostCenter,
					workType: groupedByProject[project][0].accountWorkType,
					attachments: groupedByProject[project][0].attachments
				};
				let auxCommentsLine: any = {};

				groupedByProject[project].map(e => {
					if (getDayOfWeek(e.timeEntryDate) == 'Monday') {
						auxLine = {
							...auxLine,
							mon:
								decimalToHours(e.hours).hours.toString().padStart(2, '0') +
								':' +
								decimalToHours(e.hours).minutes.toString().padStart(2, '0'),
							projectId: e.accountProjectId
						};
					}
					if (getDayOfWeek(e.timeEntryDate) == 'Tuesday') {
						auxLine = {
							...auxLine,
							tue:
								decimalToHours(e.hours).hours.toString().padStart(2, '0') +
								':' +
								decimalToHours(e.hours).minutes.toString().padStart(2, '0'),
							projectId: e.accountProjectId
						};
					}
					if (getDayOfWeek(e.timeEntryDate) == 'Wednesday') {
						auxLine = {
							...auxLine,
							wed:
								decimalToHours(e.hours).hours.toString().padStart(2, '0') +
								':' +
								decimalToHours(e.hours).minutes.toString().padStart(2, '0'),
							projectId: e.accountProjectId
						};
					}
					if (getDayOfWeek(e.timeEntryDate) == 'Thursday') {
						auxLine = {
							...auxLine,
							thu:
								decimalToHours(e.hours).hours.toString().padStart(2, '0') +
								':' +
								decimalToHours(e.hours).minutes.toString().padStart(2, '0'),
							projectId: e.accountProjectId
						};
					}
					if (getDayOfWeek(e.timeEntryDate) == 'Friday') {
						auxLine = {
							...auxLine,
							fri:
								decimalToHours(e.hours).hours.toString().padStart(2, '0') +
								':' +
								decimalToHours(e.hours).minutes.toString().padStart(2, '0'),
							projectId: e.accountProjectId
						};
					}
					if (getDayOfWeek(e.timeEntryDate) == 'Saturday') {
						auxLine = {
							...auxLine,
							sat:
								decimalToHours(e.hours).hours.toString().padStart(2, '0') +
								':' +
								decimalToHours(e.hours).minutes.toString().padStart(2, '0'),
							projectId: e.accountProjectId
						};
					}
					if (getDayOfWeek(e.timeEntryDate) == 'Sunday') {
						auxLine = {
							...auxLine,
							sun:
								decimalToHours(e.hours).hours.toString().padStart(2, '0') +
								':' +
								decimalToHours(e.hours).minutes.toString().padStart(2, '0'),
							projectId: e.accountProjectId
						};
					}
					if (e.description) {
						auxCommentsLine = {
							projectName: e.projectName,
							taskName: e.taskName,
							date: format(new Date(e.timeEntryDate), 'dd-MM-yyyy'),
							total:
								decimalToHours(e.hours).hours.toString().padStart(2, '0') +
								':' +
								decimalToHours(e.hours).minutes.toString().padStart(2, '0'),
							description: e.description,
							projectId: e.accountProjectId
						};
					}
					if (!showAll) {
						if (auxCommentsLine.projectId === matchingEntry?.accountProjectId)
							auxForCommentsListTable = !isEmpty(auxCommentsLine)
								? [...auxForCommentsListTable, auxCommentsLine]
								: auxForCommentsListTable;
					} else {
						auxForCommentsListTable = !isEmpty(auxCommentsLine)
							? [...auxForCommentsListTable, auxCommentsLine]
							: auxForCommentsListTable;
					}
				});
				if (!showAll) {
					if (auxLine.projectId === matchingEntry?.accountProjectId)
						auxForTableWeekView = !isEmpty(auxLine)
							? [...auxForTableWeekView, auxLine]
							: auxForTableWeekView;
				} else {
					auxForTableWeekView = !isEmpty(auxLine) ? [...auxForTableWeekView, auxLine] : auxForTableWeekView;
				}
			}
			setTimeEntryWeekTableData(auxForTableWeekView);
			setCommentsListTableData(auxForCommentsListTable ? auxForCommentsListTable : null);
		}
	}, [approvalsTimesheetDetails, showAll]);

	// TABLE COMPONENT - columns
	const tableWeekViewColumns: ITableColumn<any>[] = useMemo(
		() => [
			{
				label: 'Project Name',
				id: 'projectName'
			},
			{ label: 'Task Name', id: 'taskName' },
			{ label: 'Cost Center', id: 'costCenter' },
			{ label: 'Work Type', id: 'workType' },
			{ label: 'Attachments', id: 'attachments' },
			{ label: 'Mon', id: 'mon' },
			{ label: 'Tue', id: 'tue' },
			{ label: 'Wed', id: 'wed' },
			{ label: 'Thu', id: 'thu' },
			{ label: 'Fri', id: 'fri' },
			{ label: 'Sat', id: 'Sat' },
			{ label: 'Sun', id: 'sun' }
		],
		[approvalsTimesheetDetails]
	);
	const tableCommentsListColumns: ITableColumn<any>[] = useMemo(
		() => [
			{
				label: 'Project Name',
				id: 'projectName'
			},
			{ label: 'Task Name', id: 'taskName' },
			{ label: 'Date', id: 'date' },
			{ label: 'Total Time', id: 'total' },
			{ label: 'Description', id: 'description' }
		],
		[approvalsTimesheetDetails]
	);

	const isUpdateButtonDisabled = disableUpdateButton();
	const isLoading = approvalsTimesheetFinal == null || loading;
	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{' '}
			<Grid
				container
				direction={'row'}
				sx={{
					justifyContent: 'space-evenly',
					alignItems: 'center'
				}}
				style={{ marginBottom: '30px' }}>
				<Grid item xs={10}>
					<Filters
						originalData={auxApprovalsTimesheet}
						data={approvalsTimesheetFinal}
						setData={setApprovalsTimesheetFinal}
						setTabSelected={setTabSelected}></Filters>
				</Grid>
			</Grid>
			<TabHeader
				selected={tabSelected}
				className={classes.tabheader}
				handleSelected={(event: React.SyntheticEvent<Element, Event>, newValue: number) =>
					setTabSelected(newValue)
				}
				variant="scrollable"
				scrollButtons="auto">
				{tabsTimesheetFinal.map((tab, index) => (
					<Tab key={`Resume_${tab.id}_tab_button`} label={tab.label} index={index} />
				))}
			</TabHeader>
			<Grid
				container
				direction="column"
				style={{
					marginBottom: '100px'
				}}>
				{/* My Projects Time Entry Approvals */}
				{approvalsTimesheetFinal?.approvalEntriesForProjectManagerSummarized?.length > 0 &&
					tabsTimesheetFinal[tabSelected]?.id === 'myProjectsTimeEntryApprovals' && (
						<>
							{groupedDataApprovalEntriesForProjectManager && (
								<Grid item xs={12}>
									<CollapseTable
										rowsHeader={rowsHeaderApprovalEntriesForProjectManager}
										data={groupedDataApprovalEntriesForProjectManager}
										mainActions={{
											approveAll: approveAllProjectTimeEntryApprovals,
											rejectAll: rejectAllProjectTimeEntryApprovals
										}}
										secondaryActions={{
											approve: approveProjectTimeEntryApprovals,
											reject: rejectProjectTimeEntryApprovals,
											comment: commentEntryApproval,
											details: setOpenDetailsModal,
											entryToDetails: setEntryIdToDetails
										}}></CollapseTable>
								</Grid>
							)}
						</>
					)}
				{/* My Team Time Entry Approvals */}
				{approvalsTimesheetFinal?.approvalEntriesForTeamLeadSummarized?.length > 0 &&
					tabsTimesheetFinal[tabSelected]?.id === 'myTeamTimeEntryApprovals' && (
						<>
							{groupedDataApprovalEntriesForTeamLeadSummarized && (
								<Grid item>
									<CollapseTable
										rowsHeader={rowsHeaderApprovalEntriesForProjectManager}
										data={groupedDataApprovalEntriesForTeamLeadSummarized}
										mainActions={{
											approveAll: approveAllProjectTimeEntryApprovals,
											rejectAll: rejectAllProjectTimeEntryApprovals
										}}
										secondaryActions={{
											approve: approveProjectTimeEntryApprovals,
											reject: rejectProjectTimeEntryApprovals,
											comment: commentEntryApproval,
											details: setOpenDetailsModal,
											entryToDetails: setEntryIdToDetails
										}}></CollapseTable>
								</Grid>
							)}
						</>
					)}
				{/* Employee Manager Time Entry Approvals */}
				{approvalsTimesheetFinal?.approvalEntriesForEmployeeManagerSummarized?.length > 0 &&
					tabsTimesheetFinal[tabSelected]?.id === 'employeeManagerTimeEntryApprovals' && (
						<>
							{groupedDataApprovalEntriesForEmployeeManagerSummarized && (
								<Grid item>
									<CollapseTable
										rowsHeader={rowsHeaderApprovalEntriesForProjectManager}
										data={groupedDataApprovalEntriesForEmployeeManagerSummarized}
										mainActions={{
											approveAll: approveAllProjectTimeEntryApprovals,
											rejectAll: rejectAllProjectTimeEntryApprovals
										}}
										secondaryActions={{
											approve: approveProjectTimeEntryApprovals,
											reject: rejectProjectTimeEntryApprovals,
											comment: commentEntryApproval,
											details: setOpenDetailsModal,
											entryToDetails: setEntryIdToDetails
										}}></CollapseTable>
								</Grid>
							)}
						</>
					)}
				{/* Project Manager Time Off Approvals */}
				{approvalsTimesheetFinal?.approvalEntriesForProjectManagerTimeOff?.length > 0 &&
					tabsTimesheetFinal[tabSelected]?.id === 'projectManagerTimeOffApprovals' && (
						<>
							{groupedDataAccountProjectsSpecificEmployeeTimeOff && (
								<Grid item>
									<CollapseTable
										isTimeOff={true}
										rowsHeader={rowsHeaderApprovalEntriesForProjectManagerTimeOff}
										data={groupedDataAccountProjectsSpecificEmployeeTimeOff}
										mainActions={{
											approveAll: approveAllProjectTimeEntryApprovals,
											rejectAll: rejectAllProjectTimeEntryApprovals
										}}
										secondaryActions={{
											approve: approveProjectTimeEntryApprovals,
											reject: rejectProjectTimeEntryApprovals,
											comment: commentEntryApproval,
											details: setOpenDetailsModal,
											entryToDetails: setEntryIdToDetails
										}}></CollapseTable>
								</Grid>
							)}
						</>
					)}
				{/* Specific External User Time Entry Approvals */}
				{approvalsTimesheetFinal?.approvalEntriesForSpecificExternalUserSummarized?.length > 0 &&
					tabsTimesheetFinal[tabSelected]?.id === 'specificExternalUserTimeEntryApprovals' && (
						<>
							{groupedDataApprovalEntriesForSpecificExternalUserSummarized && (
								<Grid item>
									<CollapseTable
										rowsHeader={rowsHeaderApprovalEntriesForProjectManager}
										data={groupedDataApprovalEntriesForSpecificExternalUserSummarized}
										mainActions={{
											approveAll: approveAllProjectTimeEntryApprovals,
											rejectAll: rejectAllProjectTimeEntryApprovals
										}}
										secondaryActions={{
											approve: approveProjectTimeEntryApprovals,
											reject: rejectProjectTimeEntryApprovals,
											comment: commentEntryApproval,
											details: setOpenDetailsModal,
											entryToDetails: setEntryIdToDetails
										}}></CollapseTable>
								</Grid>
							)}
						</>
					)}
				{/* Specific Employee Time Entry Approvals */}
				{approvalsTimesheetFinal?.approvalEntriesForSpecificEmployeeSummarized?.length > 0 &&
					tabsTimesheetFinal[tabSelected]?.id === 'specificEmployeeTimeEntryApprovals' && (
						<>
							{groupedDataApprovalEntriesForSpecificEmployeeSummarized && (
								<Grid item>
									<CollapseTable
										rowsHeader={rowsHeaderApprovalEntriesForProjectManager}
										data={groupedDataApprovalEntriesForSpecificEmployeeSummarized}
										mainActions={{
											approveAll: approveAllProjectTimeEntryApprovals,
											rejectAll: rejectAllProjectTimeEntryApprovals
										}}
										secondaryActions={{
											approve: approveProjectTimeEntryApprovals,
											reject: rejectProjectTimeEntryApprovals,
											comment: commentEntryApproval,
											details: setOpenDetailsModal,
											entryToDetails: setEntryIdToDetails
										}}></CollapseTable>
								</Grid>
							)}
						</>
					)}
				{/* Specific Employee Time Off Approvals */}
				{approvalsTimesheetFinal?.approvalEntriesSpecificEmployeeForTimeOff?.length > 0 &&
					tabsTimesheetFinal[tabSelected]?.id === 'projectManagerTimeOffApprovals' && (
						<>
							{groupedDataApprovalEntriesSpecificEmployeeForTimeOff && (
								<Grid item>
									<CollapseTable
										rowsHeader={rowsHeaderApprovalEntriesForProjectManager}
										data={groupedDataApprovalEntriesSpecificEmployeeForTimeOff}
										mainActions={{
											approveAll: approveAllProjectTimeEntryApprovals,
											rejectAll: rejectAllProjectTimeEntryApprovals
										}}
										secondaryActions={{
											approve: approveProjectTimeEntryApprovals,
											reject: rejectProjectTimeEntryApprovals,
											comment: commentEntryApproval,
											details: setOpenDetailsModal,
											entryToDetails: setEntryIdToDetails
										}}></CollapseTable>
								</Grid>
							)}
						</>
					)}
			</Grid>
			<Grid
				container
				direction="row"
				id="footerButtonsID"
				style={{
					position: 'fixed',
					bottom: 0,
					left: 0,
					right: 0,
					backgroundColor: 'white',
					paddingLeft: '30px',
					paddingRight: '30px',
					paddingBottom: '20px',
					paddingTop: '20px',
					boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
					zIndex: 1000
				}}
				sx={{
					justifyContent: 'flex-end',
					alignItems: 'center'
				}}>
				<Grid item id={'footerLegendID'}>
					<Button
						disabled={isUpdateButtonDisabled}
						color="primary"
						variant="contained"
						onClick={() => {
							updateTimeEntryApprovals();
						}}>
						Update Time Entry Approvals
					</Button>
				</Grid>
			</Grid>
			<Dialog
				title={
					<div>
						Period Details of <span style={{ marginRight: '150px' }}>{otherInfoDetails?.employeeName}</span>
						Period {otherInfoDetails?.startDate} - {otherInfoDetails?.endDate}
					</div>
				}
				maxWidth="xl"
				modal={{
					open: Boolean(openDetailsModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') {
							setOpenDetailsModal(false);
						}
					},
					content: (
						<>
							{loading && <LoadingOverlay />}
							<Grid container>
								<Grid item xs={12}>
									<Checkbox
										value={showAll}
										color="primary"
										label="Show All"
										additionalOnChange={() => {
											setShowAll(!showAll);
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									{tableWeekViewPayload?.length > 0 && (
										<>
											<Typography
												style={{
													borderRadius: '5px',
													color: 'white',
													height: '40px',
													display: 'flex',
													alignItems: 'center', // Vertically centers the content
													justifyContent: 'start', // If you want it aligned to the left, otherwise use 'center'
													paddingLeft: '30px' // Optional: You can control horizontal padding instead of using margin
												}}>
												<div>Time Entry Week View</div>
											</Typography>

											<Table
												tableData={tableWeekViewPayload}
												columns={tableWeekViewColumns}></Table>
										</>
									)}
								</Grid>
								<Grid item xs={12}>
									{tableCommentsListPayload?.length > 0 && (
										<>
											<Typography
												style={{
													borderRadius: '5px',
													color: 'white',
													height: '40px',
													display: 'flex',
													alignItems: 'center', // Vertically centers the content
													justifyContent: 'start', // If you want it aligned to the left, otherwise use 'center'
													paddingLeft: '30px' // Optional: You can control horizontal padding instead of using margin
												}}>
												Comments List
											</Typography>
											<Table
												tableData={tableCommentsListPayload}
												columns={tableCommentsListColumns}></Table>
										</>
									)}
								</Grid>
							</Grid>
						</>
					)
				}}
				actions={[
					{
						id: 'cancel',
						label: 'Close',
						color: 'primary',
						variant: 'text',
						onClick: () => {
							setShowAll(false);
							setOpenDetailsModal(false);
							setOtherInfoDetails(false);
						}
					}
				]}
			/>
		</>
	);
};
export default withLayout(Timesheet);
