/* eslint-disable @typescript-eslint/no-explicit-any, no-plusplus */
//* EXTERNAL LIBS
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';

//* EXTERNAL LIBS --> MUI
import { Grid, InputAdornment } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { Input, Select, DatePicker, InfoField } from 'xpand-ui/forms';
import { sizes } from 'xpand-ui/utils/handlers';

//* TYPINGS

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import GroupCollapse from '../components/GroupCollapse';
import { PROPOSAL, CSC, EMPLOYEE, COLLABTYPE } from '../constants';
import {
	BALWURK_CONTRACTOR_ID,
	XPAND_CONTRACTOR_ID,
	XPAND_EMPLOYEE_ID,
	XPAND_EMPLOYEE_ID_HR,
	XPAND_EMPLOYEE_ID_UK,
	XPAND_EMPLOYEE_ID_SE,
	XPAND_EMPLOYEE_ID_DE,
	XPAND_CONTRACTOR_ID_SE,
	XPAND_CONTRACTOR_ID_DE,
	XPAND_CONTRACTOR_ID_UK,
	XPAND_CONTRACTOR_ID_HR,
	BALWURK_EMPLOYEE_ID
} from 'lib/utils/constants';
import { addNotification } from 'lib/utils/notifications';

//* COMPONENT INTERFACES
interface IEmployeeCategorySelect {
	id: string;
	label: string;
}

//* COMPONENT

const General: FC<any> = ({
	type,
	control,
	errors,
	getValues,
	setValue,
	watch,
	reset,
	initialFormData,
	selectOptions,
	handleSelectHelper,
	populateFormWithProposal,
	isContractor,
	setIsContractor,
	updateIRSTax,
	setTabs,
	defaultValues,
	initFinancialValues,
	getAnnualGrossValue,
	formFields,
	companyDomain
}) => {
	const { isProp, isCSC, isEmp, isCollabType } = useMemo(
		() => ({
			isProp: type === PROPOSAL,
			isCSC: type === CSC,
			isEmp: type === EMPLOYEE,
			isCollabType: type === COLLABTYPE
		}),
		[type]
	);

	const validContractorIDs = [
		XPAND_CONTRACTOR_ID,
		XPAND_CONTRACTOR_ID_SE,
		XPAND_CONTRACTOR_ID_DE,
		XPAND_CONTRACTOR_ID_UK,
		XPAND_CONTRACTOR_ID_HR,
		BALWURK_CONTRACTOR_ID
	];

	useEffect(() => {
		if (isProp || isEmp) {
			if (validContractorIDs.includes(watch('employeeCategory'))) {
				setIsContractor && setIsContractor('Contractor');
				setTabs &&
					setTabs([
						{
							id: 0,
							label: 'General'
						},
						{
							id: 1,
							label: 'Career & Financial'
						},
						{
							id: 2,
							label: 'Contract & Company'
						},
						{
							id: 3,
							label: 'Projects'
						},
						{
							id: 4,
							label: 'Summary'
						}
					]);
			} else if (!validContractorIDs.includes(watch('employeeCategory'))) {
				setIsContractor && setIsContractor('Employee');
				setTabs &&
					setTabs([
						{
							id: 0,
							label: 'General'
						},
						{
							id: 1,
							label: 'Career & Financial'
						},
						{
							id: 2,
							label: 'Other Benefits'
						},
						{
							id: 3,
							label: 'Contract & Company'
						},
						{
							id: 4,
							label: 'Projects'
						},
						{
							id: 5,
							label: 'Summary'
						}
					]);
			}
		} else if (isCSC) {
			setTabs &&
				setTabs([
					{
						id: 0,
						label: 'General'
					},
					{
						id: 1,
						label: 'Career & Financial'
					},
					{
						id: 2,
						label: 'Other Benefits'
					},
					{
						id: 3,
						label: 'Contract & Company'
					},
					{
						id: 4,
						label: 'Summary'
					}
				]);
		} else if (isCollabType) {
			if (validContractorIDs.includes(watch('employeeCategory'))) {
				setIsContractor && setIsContractor('Contractor');
				setTabs &&
					setTabs([
						{
							id: 0,
							label: 'General'
						},
						{
							id: 1,
							label: 'Career & Financial'
						},
						{
							id: 2,
							label: 'Contract & Company'
						},
						{
							id: 3,
							label: 'Summary'
						}
					]);
			} else if (!validContractorIDs.includes(watch('employeeCategory'))) {
				setIsContractor && setIsContractor('Employee');
				setTabs &&
					setTabs([
						{
							id: 0,
							label: 'General'
						},
						{
							id: 1,
							label: 'Career & Financial'
						},
						{
							id: 2,
							label: 'Other Benefits'
						},
						{
							id: 3,
							label: 'Contract & Company'
						},
						{
							id: 4,
							label: 'Summary'
						}
					]);
			}
		}
	}, [watch('employeeCategory'), selectOptions?.collaborationProposals, initialFormData]);

	//If exists older business unit resets fields related to it
	useEffect(() => {
		if (
			watch('company') == 'PT' &&
			selectOptions?.businessUnit
				?.filter(
					e =>
						e.id !== '557D9484E0C145948EA158D44DB3CB69' &&
						e.id !== '35AE5D04172749B58BD5655E65B9FAD2' &&
						e.id !== '08696BAD1A8F41FC9DF0165D31C65D63' &&
						e.id !== 'C41D5025CD334655A0C066447E022621' &&
						e.id !== 'AE10005AA2FF4265BBCF39D19C6A3CE5' &&
						e.id !== '6C0EBDB505CF4C0EA4EE172BBCD4953B' &&
						e.id !== '11D835BCD8174D3B8A65D2D9313A1A4B'
				)
				.find(e => e.id == getValues('businessUnit')) == undefined
		) {
			setValue('businessUnit', '');
			setValue('division', '');
			setValue('department', '');
		}
	}, [selectOptions?.businessUnit]);
	const typeName =
		(isProp && 'collaboration proposal') ||
		(isCSC && 'employee salary category') ||
		(isEmp && 'collaboration') ||
		(isCollabType && 'edit collaboration type');

	const handleVacationDays = useMemo(
		() => (formData: any, date: string) => {
			let vacationDays = 0;
			if (date) {
				const parse = date.split(' ')[0].split('-').reverse();
				const day = parseInt(parse[0]);
				let month = parseInt(parse[1]);
				if (day > 5)
					// if the person is hired after day 5, it will use the next month's vacations
					month++;
				if (month <= 3)
					// Jan, Feb or Mar
					vacationDays = 20;
				else vacationDays = 26 - month * 2;
			}

			if (!formData.noTimeOffPolicy) setValue('vacationDays', vacationDays, { shouldValidate: true });
		},
		[]
	);

	const checkStartDateUpdateIRSTax = () => {
		if (watch('startDate') == '' || watch('startDate') == null) {
			addNotification('danger', 'Start date is necessary to update IRS Tax.', 5);
		} else {
			updateIRSTax(watch());
		}
	};

	const loadFormWithProposal = ({ value }: { value: string }) => populateFormWithProposal(value);

	let employeeCategorySelect: IEmployeeCategorySelect[] = selectOptions.businessPartnerCategories;
	if (typeName !== 'employee salary category') {
		employeeCategorySelect = selectOptions.businessPartnerCategories.filter(item => {
			return item.label !== 'Vendor';
		});
	}

	useEffect(() => {
		if (employeeCategorySelect != null || employeeCategorySelect != undefined) {
			if (validContractorIDs.includes(watch('employeeCategory'))) {
				employeeCategorySelect.forEach(element => {
					switch (element.id) {
						case XPAND_CONTRACTOR_ID:
							setValue('employeeCategory', XPAND_CONTRACTOR_ID);
							break;
						case XPAND_CONTRACTOR_ID_DE:
							setValue('employeeCategory', XPAND_CONTRACTOR_ID_DE);
							break;
						case XPAND_CONTRACTOR_ID_HR:
							setValue('employeeCategory', XPAND_CONTRACTOR_ID_HR);
							break;
						case XPAND_EMPLOYEE_ID_SE:
							setValue('employeeCategory', XPAND_CONTRACTOR_ID_SE);
							break;
						case XPAND_CONTRACTOR_ID_UK:
							setValue('employeeCategory', XPAND_CONTRACTOR_ID_UK);
							break;
						case BALWURK_CONTRACTOR_ID:
							setValue('employeeCategory', BALWURK_CONTRACTOR_ID);
							break;
					}
				});
			} else {
				employeeCategorySelect.forEach(element => {
					switch (element.id) {
						case XPAND_EMPLOYEE_ID:
							setValue('employeeCategory', XPAND_EMPLOYEE_ID);
							break;
						case XPAND_EMPLOYEE_ID_DE:
							setValue('employeeCategory', XPAND_EMPLOYEE_ID_DE);
							break;
						case XPAND_EMPLOYEE_ID_HR:
							setValue('employeeCategory', XPAND_EMPLOYEE_ID_HR);
							break;
						case XPAND_EMPLOYEE_ID_SE:
							setValue('employeeCategory', XPAND_EMPLOYEE_ID_SE);
							break;
						case XPAND_EMPLOYEE_ID_UK:
							setValue('employeeCategory', XPAND_EMPLOYEE_ID_UK);
							break;
						case BALWURK_EMPLOYEE_ID:
							setValue('employeeCategory', BALWURK_EMPLOYEE_ID);
							break;
					}
				});
			}
		}
	}, [watch('company'), defaultValues]);

	const generalComponent = (
		<>
			{/* ----------- START FIRST ROW ----------- */}
			{/* ----------- LEFT COLUMN ----------- */}
			{/* Full Name */}
			{formFields?.formFieldsConfig?.fullName && (
				<Grid item {...sizes[6]}>
					<InfoField name="name" label="Full Name" getValues={getValues} />
				</Grid>
			)}
			{/* COLLABORATION TYPE */}
			{formFields?.formFieldsConfig?.collabType && (
				<Grid item {...sizes[6]}>
					<InfoField name="collaborationType" label="Collaboration Type" getValues={getValues} />
				</Grid>
			)}
			{/* ----------- RIGHT COLUMN ----------- */}
			{/* COMPANY */}
			{formFields?.formFieldsConfig?.company && !isCSC && !isProp && (
				<Grid item {...sizes[6]}>
					<InfoField name="companyName" label="Company" getValues={getValues} />
				</Grid>
			)}
			{/* COMPANY */}
			{formFields?.formFieldsConfig?.company && isCSC && (
				<Grid item {...sizes[6]}>
					<InfoField label="Company" value={initialFormData?.company?.name || ''} helperText=" " />
				</Grid>
			)}
			{/* COMPANY */}
			{formFields?.formFieldsConfig?.company && isProp && (
				<Grid item {...sizes[6]}>
					<Select
						required
						name="company"
						label="Company"
						options={selectOptions.company || []}
						additionalOnChange={(item: { value: string }) => {
							handleSelectHelper('company', item.value);
							updateIRSTax(watch());
						}}
						control={control}
						errors={errors}
					/>
				</Grid>
			)}
			{/* ----------- END FIRST ROW ----------- */}
			{/* ----------- START SECOND ROW ----------- */}
			{/* ----------- LEFT COLUMN ----------- */}
			{/* COLLABORATION START DATE  */}
			{formFields?.formFieldsConfig?.collabStartDate && (
				<Grid item {...sizes[6]}>
					<DatePicker
						required
						name="startDate"
						label="Collaboration Start Date"
						helperText={`A preview of the date when this ${typeName} will start`}
						additionalOnChange={(item: string) => {
							handleSelectHelper('startDate', item);
							handleVacationDays(watch(), item as string);
							initFinancialValues(watch());
							checkStartDateUpdateIRSTax();
							getAnnualGrossValue(watch());
						}}
						control={control}
						errors={errors}
					/>
				</Grid>
			)}
			{/* ----------- RIGHT COLUMN ----------- */}
			{/* BUSINESS UNIT */}
			{formFields?.formFieldsConfig?.businessUnit && (isProp || isEmp || isCollabType) && (
				<Grid item {...sizes[6]}>
					<Select
						required
						name="businessUnit"
						label="Business Unit"
						options={
							getValues('company') == 'PT'
								? selectOptions.businessUnit.filter(
										e =>
											e.id !== '557D9484E0C145948EA158D44DB3CB69' &&
											e.id !== '35AE5D04172749B58BD5655E65B9FAD2' &&
											e.id !== '08696BAD1A8F41FC9DF0165D31C65D63' &&
											e.id !== 'C41D5025CD334655A0C066447E022621' &&
											e.id !== 'AE10005AA2FF4265BBCF39D19C6A3CE5' &&
											e.id !== '6C0EBDB505CF4C0EA4EE172BBCD4953B' &&
											e.id !== '11D835BCD8174D3B8A65D2D9313A1A4B'
								  )
								: selectOptions.businessUnit || []
						}
						additionalOnChange={(item: { value: string }) => {
							handleSelectHelper('businessUnit', item.value);
						}}
						control={control}
						errors={errors}
					/>
				</Grid>
			)}
			{/* BUSINESS UNIT */}
			{formFields?.formFieldsConfig?.businessUnit && isCSC && (
				<Grid item {...sizes[6]}>
					<InfoField
						label="Business Unit"
						value={initialFormData?.employeeInfo?.xPHRAdOrgBunit$_identifier || ''}
					/>
				</Grid>
			)}
			{/* ----------- END SECOND ROW ----------- */}
			{/* ----------- START THIRD ROW ----------- */}
			{/* ----------- LEFT COLUMN ----------- */}
			{isCSC && <Grid item {...sizes[6]}></Grid>}
			{/* NAME */}
			{formFields?.formFieldsConfig?.name && (
				<Grid item {...sizes[6]}>
					<Input required name="name" label="Name" control={control} errors={errors} />
				</Grid>
			)}

			{/* USERNAME */}
			{formFields?.formFieldsConfig?.username && (
				<Grid item {...sizes[6]}>
					<Input
						required={isCollabType}
						disabled={isCollabType}
						name="username"
						label="Username"
						control={control}
						errors={errors}
					/>
				</Grid>
			)}

			{/* ----------- RIGHT COLUMN ----------- */}
			{/* DIVISION */}
			{formFields?.formFieldsConfig?.division && !isCSC && (
				<Grid item {...sizes[6]}>
					<Select
						required
						name="division"
						label="Division"
						options={selectOptions.division || []}
						additionalOnChange={(item: { value: string }) => {
							handleSelectHelper('division', item.value);
						}}
						control={control}
						errors={errors}
					/>
				</Grid>
			)}
			{/* DIVISION */}
			{formFields?.formFieldsConfig?.division && isCSC && (
				<Grid item {...sizes[6]}>
					<InfoField
						label="Division"
						value={initialFormData?.employeeInfo?.xphrAdOrgDivision$_identifier || ''}
					/>
				</Grid>
			)}

			{/* ----------- END THIRD ROW ----------- */}
			{/* ----------- START FOURTH ROW ----------- */}
			{/* ----------- LEFT COLUMN ----------- */}
			{isCSC && <Grid item {...sizes[6]}></Grid>}
			{/* EMAIL */}
			{formFields?.formFieldsConfig?.email && !isProp && !isCollabType && (
				<Grid item {...sizes[6]}>
					<Input
						name="email"
						label="Email Address"
						InputProps={{ endAdornment: <InputAdornment position="end">@{companyDomain}</InputAdornment> }}
						control={control}
						errors={errors}
					/>
				</Grid>
			)}
			{/* EMAIL */}
			{formFields?.formFieldsConfig?.email && (isProp || isCollabType) && (
				<Grid item {...sizes[6]}>
					<Input
						required={isCollabType}
						disabled={isCollabType}
						name="email"
						label="Email Address"
						control={control}
						errors={errors}
					/>
				</Grid>
			)}
			{/* ----------- RIGHT COLUMN ----------- */}
			{/* DEPARTMENT */}
			{formFields?.formFieldsConfig?.department && isCSC && (
				<Grid item {...sizes[6]}>
					<InfoField
						label="Department"
						value={initialFormData?.employeeInfo?.xphrAdOrgDepartment$_identifier || ''}
					/>
				</Grid>
			)}
			{/* DEPARTMENT */}
			{formFields?.formFieldsConfig?.department && !isCSC && (
				<Grid item {...sizes[6]}>
					<Select
						required
						name="department"
						label="Department"
						options={selectOptions.department || []}
						additionalOnChange={(item: { value: string }) => {
							handleSelectHelper('department', item.value);
						}}
						control={control}
						errors={errors}
					/>
				</Grid>
			)}
			{/* ----------- END FOURTH ROW ----------- */}
			{/* ----------- START FIFTH ROW ----------- */}
			{/* ----------- LEFT COLUMN ----------- */}
			{isCSC && <Grid item {...sizes[6]}></Grid>}
			{/* EMPLOYEE CATEGORY  */}
			{formFields?.formFieldsConfig?.employeeCategory && (
				<Grid item {...sizes[6]}>
					<Select
						required={isCollabType}
						disabled={isCollabType}
						name="employeeCategory"
						label="Employee Category"
						additionalOnChange={(item: { label: string }) => {
							if (isCollabType) {
								if (item.label === 'Contractor') {
									reset({
										...defaultValues,

										employeeCategory: getValues('employeeCategory'),
										name: getValues('name'),
										companyName: getValues('companyName'),
										businessUnit: getValues('businessUnit'),
										division: getValues('division'),
										department: getValues('department'),
										username: getValues('username'),
										email: getValues('email'),
										startDate: getValues('startDate'),
										company: getValues('company'),
										careerPosition: getValues('careerPosition'),
										careerJobRole: getValues('careerJobRole'),
										careerJobRoleLevel: getValues('careerJobRoleLevel'),
										holdersNumber: getValues('holdersNumber'),
										disabled: getValues('disabled'),
										token: getValues('token'),
										primaryOffice: getValues('primaryOffice'),
										workingOffice: getValues('workingOffice'),
										contractType: getValues('contractType'),
										customerInContract: getValues('customerInContract'),
										contractPosition: getValues('contractPosition'),
										endDate: getValues('endDate'),
										careerManager: getValues('careerManager'),
										employeeManager: getValues('employeeManager'),
										holidayType: getValues('holidayType'),
										socialSecurityNumber: getValues('socialSecurityNumber'),
										ihtPercentage: 0,
										hasTwelfths: false,
										healthInsuranceCheck: false,
										healthInsuranceValue: '0.0',
										tsuExemption: false,
										monthsApplicable: 0
									});
								} else {
									reset({
										...defaultValues,

										employeeCategory: getValues('employeeCategory'),
										name: getValues('name'),
										companyName: getValues('companyName'),
										businessUnit: getValues('businessUnit'),
										division: getValues('division'),
										department: getValues('department'),
										username: getValues('username'),
										email: getValues('email'),
										startDate: getValues('startDate'),
										company: getValues('company'),
										careerPosition: getValues('careerPosition'),
										careerJobRole: getValues('careerJobRole'),
										careerJobRoleLevel: getValues('careerJobRoleLevel'),
										holdersNumber: getValues('holdersNumber'),
										disabled: getValues('disabled'),
										token: getValues('token'),
										primaryOffice: getValues('primaryOffice'),
										workingOffice: getValues('workingOffice'),
										contractType: getValues('contractType'),
										customerInContract: getValues('customerInContract'),
										contractPosition: getValues('contractPosition'),
										endDate: getValues('endDate'),
										careerManager: getValues('careerManager'),
										employeeManager: getValues('employeeManager'),
										holidayType: getValues('holidayType'),
										socialSecurityNumber: getValues('socialSecurityNumber')
									});
								}
								setIsContractor(item.label);
							} else {
								if (watch('company') !== 'HR') {
									reset({
										...defaultValues,
										employeeCategory: getValues('employeeCategory'),
										name: getValues('name'),
										companyName: getValues('companyName'),
										businessUnit: getValues('businessUnit'),
										division: getValues('division'),
										department: getValues('department'),
										username: getValues('username'),
										email: getValues('email'),
										startDate: getValues('startDate'),
										company: getValues('company'),
										admissionProcessSheetId: getValues('admissionProcessSheetId')
									});
									setIsContractor(item.label);
								}
							}
						}}
						options={employeeCategorySelect || []}
						control={control}
						errors={errors}
					/>
				</Grid>
			)}
			{/* ----------- RIGHT COLUMN ----------- */}
			{/* COLLABORATION ROLE */}
			{formFields?.formFieldsConfig?.collabRole && (
				<Grid item {...sizes[6]}>
					<Select
						required
						name="collaborationRole"
						label="Collaboration Role"
						options={selectOptions.collaborationRoles || []}
						helperText="Employee future role at the company."
						control={control}
						errors={errors}
					/>
				</Grid>
			)}
			{/* ----------- END FIFTH ROW ----------- */}
			{/* ----------- START SIXTH ROW ----------- */}
			{/* ----------- LEFT COLUMN ----------- */}
			{/* JIRA LINK  */}
			{formFields?.formFieldsConfig?.jiraLink && (
				<Grid item {...sizes[6]}>
					<Input
						name="jiraCandidateApplication"
						label="Jira Candidate Application Link"
						control={control}
						errors={errors}
					/>
				</Grid>
			)}
			{/* ----------- END SIXTH ROW ----------- */}
		</>
	);

	return (
		<Grid
			container
			spacing={2}
			justifyContent="center"
			style={{
				paddingBottom: '20px'
			}}>
			{/* USER DATA */}
			{isProp && (
				<GroupCollapse
					showContent={false}
					collapse={{
						id: 'userData',
						label: 'LOAD DATA FROM ANOTHER USER'
					}}>
					{/* SELECT PROPOSAL TO LOAD DATA FROM */}
					<Grid item {...sizes[12]}>
						<Select
							name="usersToLoadData"
							label="Select proposal to load"
							options={selectOptions.collaborationProposals}
							additionalOnChange={value => {
								loadFormWithProposal(value);
								// updateTabs();
							}}
							control={control}
							errors={errors}
						/>
					</Grid>
				</GroupCollapse>
			)}
			<GroupCollapse
				collapse={{
					id: 'general',
					label: 'General Information'
				}}>
				{generalComponent}
			</GroupCollapse>
		</Grid>
	);
};

export default withLayout(General);
