import * as yup from 'yup';
import { string, boolean } from 'lib/utils/formUtils';

export const schema = yup.object().shape({
	content: yup
		.string()
		.nullable()
		.transform((value, originalValue) =>
			originalValue && originalValue.trim && originalValue.trim() === '' ? null : value
		),
	placement: string,
	target: string,
	title: string,
	disableScrolling: boolean,
	spotlightClicks: boolean
});

export const defaultValues = {
	content: '',
	placement: '',
	target: '',
	title: '',
	disableScrolling: false,
	spotlightClicks: false
};
