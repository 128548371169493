/* eslint-disable func-names */
import { Moment } from 'moment';
import * as yup from 'yup';

import { INSTANCE } from 'lib/utils/constants';
import { parseDateToManage } from 'xpand-ui/utils/dates';

const requiredMessage = 'This field is required';

const regexValues = {
	phoneNumber: /^((\s?\d){9})?(\+(\s?\d){12})?$/gm,
	iban: /^[A-Z]{2}[0-9]{2}[A-Z0-9]+$/gm,
	zipCode: /^\d{4}(-\d{3})?$/gm
};

const regexCarLicensePlatePT = new RegExp('^[A-Za-z0-9]{2}[-][A-Za-z0-9]{2}[-][A-Za-z0-9]{2}$');
const regexCC = new RegExp('^[0-9]{8}[  ]*[0-9][  ][A-Z0-9]{2}[0-9]$');
const regexIBAN = new RegExp('[A-Z]{2}[0-9]{2}[A-Z0-9]+$');
const regexNISS = new RegExp('^[0-9]{11}$');
const regexNIF = new RegExp('^[0-9]{9}$');

// New Project
const regexProjectCode = new RegExp(
	'^([A-Z|0-9|_]+)[.]([A-Z|0-9]+)((_XPPT)|(_XPUK)|(_BPTRF)|(_CFAPP)|(_CSERV)|(_CXHUB)|(_CXRES)|(_DTANA)|(_DTENG)|(_DTHUB)|(_DTRES)|(_DTSCI)|(_PLENG)|(_USEXP)|(_WBACK)|(_WBHUB)|(_HR)|(_LOG)|(_M&S)|(_MF)|(_T&PE)|(_INS))?$'
);

const regexProjectCode2 = new RegExp(
	'^([A-Z|0-9|_]+)[.]((HUB_|BPTRF_|CFAPP_|CSERV_|CXHUB_|CXRES_|DTANA_|DTENG_|DTHUB_|DTRES_|DTSCI_|PLENG_|USEXP_|WBACK_|WBHUB_))([A-Z|0-9]+)$'
);

const regexProjectCodeBalwurk2 = new RegExp('^([A-Z|0-9|_]+)[.]((HUB_|AS_|GRC_))([A-Z|0-9]+)$');

const combinedProjectCodeRules = new RegExp(`${regexProjectCode.source}|${regexProjectCode2.source}`);

const combinedProjectCodeRulesBalwurk = new RegExp(`${regexProjectCode.source}|${regexProjectCodeBalwurk2.source}`);

const regexProjectName = new RegExp('^([A-Za-z0-9\u00C0-\u00FF ]+)[\\s][-][\\s]([A-Za-z0-9\u00C0-\u00FF ]+)$');

const regexProjectName2 = new RegExp(
	'^([A-Za-z0-9\u00C0-\u00FF ]+)[\\s][-][\\s]((HUB|BPTRF|CFAPP|CSERV|CXHUB|CXRES|DTANA|DTENG|DTHUB|DTRES|DTSCI|PLENG|USEXP|WBACK|WBHUB))[\\s][-][\\s]([A-Za-z0-9\u00C0-\u00FF ]+)$'
);

const regexProjectNameBalwurk2 = new RegExp(
	'^([A-Za-z0-9\u00C0-\u00FF ]+)[\\s][-][\\s]((HUB|AS|GRC))[\\s][-][\\s]([A-Za-z0-9\u00C0-\u00FF ]+)$'
);

const combinedProjectNameRules = new RegExp(`${regexProjectName.source}|${regexProjectName2.source}`);

const combinedProjectNameRulesBalwurk = new RegExp(`${regexProjectName.source}|${regexProjectNameBalwurk2.source}`);

const parseDateString = (
	value: string | Moment,
	originalValue: Moment | string
): Date | string | Moment | boolean | null => {
	if (!originalValue) return null;

	let payload = null;
	payload = originalValue;

	if (typeof originalValue === 'object') payload = new Date(parseDateToManage(originalValue));

	if (typeof originalValue === 'string') {
		if (originalValue.trim() === '' || originalValue.trim().toLowerCase() === 'invalid date') {
			payload = false;
		} else {
			payload = value;
		}
	}

	return payload;
};

export const dateDefault = yup
	.date()
	.default(undefined) // CHECK IF NO PROBLEM, old value = null
	.nullable()
	.transform(parseDateString)
	.typeError('Must be a valid Date');

export const dateRequired = dateDefault.required(requiredMessage);

//USED IN NEW BUDGET MODAL IN DATE INPUT TO CHECK IF THE INPUT HAS 4 DIGITS AND IF THE NUMBER IS EQUAL OR BIGGER THAN THE CURRENT YEAR
export const dateYearDefault = yup.number().default(undefined).nullable();

export const dateYearInput = dateYearDefault
	.required(requiredMessage)
	.test('len', 'Must be exactly 4 digits', val => val?.toString().length === 4);

export const string = yup
	.string()
	.nullable()
	.max(250, 'This field exceeds the maximum limit (250)')
	.transform((value, originalValue) =>
		originalValue && originalValue.trim && originalValue.trim() === '' ? null : value
	);

export const stringLong = yup
	.string()
	.nullable()
	.max(65535, 'This field exceeds the maximum limit (65535)')
	.transform((value, originalValue) =>
		originalValue && originalValue.trim && originalValue.trim() === '' ? null : value
	);
export const stringUnlimited = yup
	.string()
	.nullable()
	.transform((value, originalValue) =>
		originalValue && originalValue.trim && originalValue.trim() === '' ? null : value
	);
export const stringRequired = string.required(requiredMessage);
export const stringLongRequired = stringLong.required(requiredMessage);
export const stringUnlimitedRequired = stringUnlimited.required(requiredMessage);

export const passwordSecure = stringRequired.test(
	'password-strength',
	'The new password must follow the rules below.',
	data => {
		const rules = {
			length: (data?.length as number) > 7 && (data?.length as number) < 17,
			upperCase: data?.match(/[A-Z]/g) !== null,
			lowerCase: data?.match(/[a-z]/g) !== null,
			numberAndSpecial:
				data?.match(/[0-9]/g) !== null && data?.match(/[ !@#$%&*()_+\-=[\]{};':"\\|,.<>/?]/) !== null
		};
		// return true -> the error will NOT show
		// return false -> the error will show
		return !Object.values(rules).includes(false);
	}
);

export const boolean = yup.bool().typeError('Must be true or false value');
export const booleanRequired = yup.bool().required();

export const number = yup.number().typeError('Must be a valid Number');
export const numberRequired = number.required(requiredMessage);

export const numberPositive = number.test(
	'bigger-than-zero',
	'This field is required',
	(data: number | null | undefined): boolean =>
		// return true -> the error will NOT show
		// return false -> the error will show
		data ? data > 0 : false
);
export const numberZeroOrPositive = number.test(
	'bigger-than-or-zero',
	'This field is required',
	(data: number | null | undefined): boolean =>
		// return true -> the error will NOT show
		// return false -> the error will show
		data ? data >= 0 : false
);
export const numberPositiveRequired = numberPositive.required(requiredMessage);
export const numberZeroOrPositiveRequired = numberZeroOrPositive.required(requiredMessage);

export const phoneNumber = string.matches(regexValues.phoneNumber, 'Phone number is not valid');
export const phoneNumberRequired = stringRequired.matches(regexValues.phoneNumber, 'Phone number is not valid');

export const ibanNumber = string.matches(regexValues.iban, 'IBAN number is not valid');
export const ibanNumberRequired = string
	.matches(regexValues.iban, 'IBAN number is not valid')
	.required(requiredMessage);

export const zipCode = string.matches(regexValues.zipCode, 'Postal code not valid');
export const zipCodeRequired = zipCode.required(requiredMessage);

export const carLicensePlateRequired = stringRequired.matches(
	regexCarLicensePlatePT,
	'The license plate is not valid. Use Format XX-XX-XX '
);
export const ccRequired = stringRequired.matches(regexCC, 'The ID Doc is not valid. Use Format NNNNNNNN N NNN');
export const nifRequired = stringRequired.matches(regexNIF, 'The NIF Doc is not valid. Use Format NNNNNNNNN');
export const nissRequired = stringRequired.matches(regexNISS, 'The NISS Doc is not valid. Use Format NNNNNNNNNNN');
export const ibanRequired = stringRequired.matches(
	regexIBAN,
	'The IBAN is not valid. It is preceded by a two-letter country code and two check digits, involving up to 34 contiguous alphanumeri characters'
);

// New Project
export const projectCodeRequired = stringRequired.matches(
	INSTANCE.toLowerCase().includes('xpand') ? combinedProjectCodeRules : combinedProjectCodeRulesBalwurk,
	'The Project Code is not valid, check the Project Naming Standard to ensure the correct format'
);
export const projectNameRequired = stringRequired.matches(
	INSTANCE.toLowerCase().includes('xpand') ? combinedProjectNameRules : combinedProjectNameRulesBalwurk,
	'The Project Name is not valid, check the Project Naming Standard to ensure the correct format'
);
export const maxDateFromDay = (date: Date): Date => {
	date.setHours(23, 59, 59);
	return date;
};

//Responsable to check if touched fields or date fields have some value, function used in <Prompt/>
export const isTouchedFieldsUsed = (touchedFields: any, getValues: any) => {
	//Check if touched fields have data if so, returns true
	if (Object.keys(touchedFields).length !== 0) {
		for (let field in touchedFields) {
			const value = getValues(field);
			if (value !== null && typeof value !== 'undefined' && value !== '') {
				return true;
			}
		}
	}

	return false;
};
