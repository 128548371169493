/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC, useEffect } from 'react';

//* EXTERNAL LIBS --> MUI
import { Grid } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { sizes } from 'xpand-ui/utils/handlers';
import { Select, Input } from 'xpand-ui/forms';

import { useStyles } from './styles';
import { INewEmployeeTabs } from '../../NewEmployee';

//* COMPONENT
const WhereEmployeeBelongs: FC<INewEmployeeTabs> = ({
	control,
	errors,
	companySelectOptions,
	setCompanyHelper,
	setValue,
	watch
}) => {
	const classes = useStyles();
	return (
		<Grid container spacing={4} className={classes.root}>
			{/* ********* Contacts (invoice) *********** */}
			<Grid item {...sizes[6]}>
				<Select
					name="company"
					label="Company"
					options={companySelectOptions.company}
					additionalOnChange={(item: { value: string }) => {
						setCompanyHelper({
							company: item.value,
							businessUnit: null,
							division: null,
							department: null
						});
						setValue('businessUnit', '');
						setValue('division', '');
						setValue('department', '');
					}}
					control={control}
					errors={errors}
					required
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Select
					name="businessUnit"
					label="Business Unit"
					helperText="Business Unit that hired this employee"
					options={
						watch('company') == 'PT'
							? companySelectOptions.businessUnit.filter(
									e =>
										e.id !== '557D9484E0C145948EA158D44DB3CB69' &&
										e.id !== '35AE5D04172749B58BD5655E65B9FAD2' &&
										e.id !== '08696BAD1A8F41FC9DF0165D31C65D63' &&
										e.id !== 'C41D5025CD334655A0C066447E022621' &&
										e.id !== 'AE10005AA2FF4265BBCF39D19C6A3CE5' &&
										e.id !== '6C0EBDB505CF4C0EA4EE172BBCD4953B' &&
										e.id !== '11D835BCD8174D3B8A65D2D9313A1A4B'
							  )
							: companySelectOptions.businessUnit
					}
					additionalOnChange={(item: { value: string }) => {
						setCompanyHelper(prev => ({
							...prev,
							businessUnit: item.value,
							division: null,
							department: null
						}));
						setValue('division', '');
						setValue('department', '');
					}}
					control={control}
					errors={errors}
					required
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Select
					name="division"
					label="Division"
					helperText="Division where the employee will work"
					options={companySelectOptions.division}
					additionalOnChange={(item: { value: string }) => {
						setCompanyHelper(prev => ({
							...prev,
							division: item.value,
							department: null
						}));
						setValue('department', '');
					}}
					control={control}
					errors={errors}
					required
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Select
					name="department"
					label="Department"
					helperText="Department where the employee will work"
					options={companySelectOptions.department}
					additionalOnChange={(item: { value: string }) => {
						setCompanyHelper(prev => ({
							...prev,
							department: item.value
						}));
					}}
					control={control}
					errors={errors}
					required
				/>
			</Grid>
		</Grid>
	);
};

export default WhereEmployeeBelongs;
